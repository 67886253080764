import React, { useState } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers/";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default function DatePickerDialog(props) {
  const [selectedDate] = useState(props.value);

  const maxDateMessage = () => {
    const defaultMessage =
      "A data selecionada não pode ser maior que a data atual";
    if (!props.maxDate) return defaultMessage;
    if (moment(selectedDate).isAfter(moment())) return defaultMessage;
    return props.maxDateMessage || defaultMessage;
  };

  const minDateMessage = () => {
    const defaultMessage = "Não há registros nesta data";
    if (!props.minDate) return defaultMessage;
    if (moment(selectedDate).isAfter(moment())) return defaultMessage;
    return props.minDateMessage || defaultMessage;
  };

  const icon = () => (
    <SvgIcon style={{ marginTop: 5, marginRight: -20 }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.98242 5.98831H2.98438V6.98636H3.98242V5.98831Z"
          fill="#6B6C7E"
        />
        <path
          d="M4.98047 5.98831H5.97852V6.98636H4.98047V5.98831Z"
          fill="#6B6C7E"
        />
        <path
          d="M7.97461 5.98831H6.97656V6.98636H7.97461V5.98831Z"
          fill="#6B6C7E"
        />
        <path
          d="M8.97266 5.98831H9.9707V6.98636H8.97266V5.98831Z"
          fill="#6B6C7E"
        />
        <path
          d="M11.9668 5.98831H10.9688V6.98636H11.9668V5.98831Z"
          fill="#6B6C7E"
        />
        <path
          d="M2.98438 7.98441H3.98242V8.98245H2.98438V7.98441Z"
          fill="#6B6C7E"
        />
        <path
          d="M5.97852 7.98441H4.98047V8.98245H5.97852V7.98441Z"
          fill="#6B6C7E"
        />
        <path
          d="M6.97656 7.98441H7.97461V8.98245H6.97656V7.98441Z"
          fill="#6B6C7E"
        />
        <path
          d="M9.9707 7.98441H8.97266V8.98245H9.9707V7.98441Z"
          fill="#6B6C7E"
        />
        <path
          d="M10.9688 7.98441H11.9668V8.98245H10.9688V7.98441Z"
          fill="#6B6C7E"
        />
        <path
          d="M3.98242 9.9805H2.98438V10.9785H3.98242V9.9805Z"
          fill="#6B6C7E"
        />
        <path
          d="M2.98438 11.9766H3.98242V12.9747H2.98438V11.9766Z"
          fill="#6B6C7E"
        />
        <path
          d="M5.97852 11.9766H4.98047V12.9747H5.97852V11.9766Z"
          fill="#6B6C7E"
        />
        <path
          d="M4.98047 9.9805H5.97852V10.9785H4.98047V9.9805Z"
          fill="#6B6C7E"
        />
        <path
          d="M7.97461 9.9805H6.97656V10.9785H7.97461V9.9805Z"
          fill="#6B6C7E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9727 2.99414C15.0645 2.99414 15.9502 3.87991 15.9502 4.97153V12.9778L12.9561 15.9719H1.97754C0.885742 15.9719 0 14.9302 0 13.8386V4.97153C0 3.87991 0.885742 2.99414 1.97754 2.99414H1.98633V0.998047C1.98633 0.446014 2.43262 0 2.98438 0C3.53711 0 3.98242 0.446014 3.98242 0.998047V2.99414H6.97656V0.998047C6.97656 0.446014 7.42285 0 7.97461 0C8.52734 0 8.97266 0.446014 8.97266 0.998047V2.99414H11.9668V0.998047C11.9668 0.446014 12.4131 0 12.9648 0C13.5176 0 13.9629 0.446014 13.9629 0.998047V2.99414H13.9727ZM11.958 13.9727L13.9541 11.9766H11.958V13.9727ZM13.9541 9.98053H9.96191V13.9727H2.05859C2.02734 13.9353 1.99609 13.876 1.99609 13.8355V4.99026H13.9541V9.98053Z"
          fill="#6B6C7E"
        />
      </svg>
    </SvgIcon>
  );

  class LocalizedUtils extends DateFnsUtils {
    getWeekdays() {
      return ["D", "S", "T", "Q", "Q", "S", "S"];
    }
  }

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBR}>
      <KeyboardDatePicker
        style={{ width: 160, backgroundColor: "#fff" }}
        //allowKeyboardControl={false}
        variant="inline"
        inputVariant="outlined"
        disableFuture={props.disableFuture}
        format="dd/MM/yyyy"
        label=""
        views={["year", "month", "date"]}
        value={props.value}
        onChange={props.onChange}
        maxDate={props.maxDate}
        maxDateMessage={maxDateMessage()}
        minDate={props.minDate || new Date("2020-01-02")}
        minDateMessage={minDateMessage}
        invalidDateMessage="Formato de data inválida"
        keyboardIcon={icon()}
      />
    </MuiPickersUtilsProvider>
  );
}
