import React, { useState } from "react";
import Api from "../../../../api";

import Modal from "../../../../components/Modal";

import { makeStyles } from "@material-ui/core/styles";

import Loading from "../../../../common/circular-progress";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default (props) => {
  const classes = useStyles();

  const {
    infoUserAdmin,
    openInactivateModal,
    setOpenInactivateModal,
    sessionToken,
    setStateSnackBar,
  } = props;

  const [loading, setLoading] = useState(false);

  const inactivateAdmin = () => {
    setLoading(true);
    const userAdminId = infoUserAdmin.id;
    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
          mutation{
            inactiveUserAdmin( userAdminId:"${userAdminId}")          
          }
        `,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.errors) {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "error",
            message: res.errors[0].message,
          }));
        } else {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "success",
            message: "Usuário inativado com sucesso!",
          }));
          props.getListAdmin();
          setOpenInactivateModal(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message:
            "Houve um erro ao tentar inativar o usuário, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  };

  const body = () => (
    <div className={classes.root}>
      {loading && (
        <Loading size={80} position="absolute" height="10vh" width="23vw" />
      )}
      <span>Deseja Realmente inativar o cadastro abaixo:</span>
      <span>
        <strong>{infoUserAdmin.name} ?</strong>
      </span>
    </div>
  );

  return (
    <Modal
      open={openInactivateModal}
      title="Inativar Usuário"
      body={body()}
      labelConfirm="Confirmar"
      onClose={() => setOpenInactivateModal(false)}
      onSubmit={() => inactivateAdmin()}
      loading={loading}
    />
  );
};
