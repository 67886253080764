import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Gravatar from './../Gravatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useDispatch } from 'react-redux'
import * as UserActions from './../../common/store/actions/user'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    justifyItems: 'flex-end'
  },
  profileButton: {
    display: 'flex',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    justifyItems: 'flex-end'
  },
  username: {
    width: 180,
    fontWeight: 600,
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: '150%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: 21,
    textAlign: 'right'
  },
  role: {
    width: 180,
    fontWeight: 'normal',
    fontSize: 10,
    fontStyle: 'normal',
    lineHeight: '150%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    color: '#6B6C7E',
    height: 12,
    textAlign: 'right'
  }
}))

export default function Profile(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user } = props

  const username = (name) => {
    const nameSplit = name.split(' ')

    if (nameSplit.length === 1) return name

    return `${nameSplit[0]} ${nameSplit[1]}`
  }

  const logout = () => {
    dispatch(UserActions.clearUserData())
    handleClose()
  }

  return (
    <div className={classes.root} edge="end">
      <div style={{ marginBottom: 15, marginTop: 15 }}>
        <Typography variant="h6" className={classes.username} >
          {user && username(user.name)}
        </Typography>
        <Typography variant="h6" className={classes.role} >
          Administrador
        </Typography>
      </div>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        className={classes.profileButton}
        onClick={handleMenu}
        color="inherit"
      >
        {user && <Gravatar username={user.name} />}
      </IconButton>
      <Menu
        id="menu-appbar"
        style={{ marginTop: 45 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={logout}>Sair</MenuItem>
      </Menu>
    </div>
  )
}
