import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../api";

import Loading from "../../common/circular-progress";
import SnackBar from "../../common/snackbar";

import ButtonStyled from "../../components/ButtonStyled";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Alert } from "@material-ui/lab";

import DatePickerDialog from "./../../components/DatePickerDialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    padding: "30px",
    "& h1": {
      margin: 0,
    },

    "& div.cards-dashboard": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      width: "100%",
      "& div.card": {
        backgroundColor: "#FFF",
        flexGrow: 1,
        margin: "20px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "50px",
        "& h2": {
          margin: "0 0 15px 0 ",
          color: "#616161",
          fontWeight: "normal",
          fontSize: "18px",
        },
        "& span": {
          color: "#4B9BFF",
          fontSize: "20px",
          fontWeight: 600,
          margin: "3px 0",
        },
      },
    },
    "& div.header-dashboard": {
      margin: "30px 0",
      display: "flex",
      justifyContent: "space-between",
      "& h1": {
        fontSize: "20px",
        fontWeight: 600,
        color: "#DF9000",
      },
      "& .MuiFormControl-root": {
        verticalAlign: "middle",
        margin: "0 10px",
      },
    },
    "& div.list-dashboard": {
      "& th.MuiTableCell-head": {
        minWidth: "300px",
        fontSize: "14px",
        color: "#6B6C7E",
        backgroundColor: "#f7f8f9",
      },
    },
  },
});

function Home() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const sessionToken = useSelector((state) => state.User.sessionToken);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [clinics, setClinics] = useState(0);
  const [healthOperators, setHealthOperators] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [secretaries, setSecretaries] = useState(0);
  const [patients, setPatients] = useState(0);
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const [listInfoDashboard, setListInfoDashboard] = useState([]);

  const onChangeInitialDate = (date) => {
    setInitialDate(date);
  };

  const onChangeFinalDate = (date) => {
    setFinalDate(date);
  };

  useEffect(() => {
    setLoading(true);
    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
          query{
            dashboardConstructor{
              clinics
              healthOperators
              doctors
              secretaries
              patients
            }
          }
        `,
      },
    })
      .then((res) => {
        setLoading(false);
        const dashboardData = res.data.dashboardConstructor;
        setClinics(dashboardData.clinics);
        setHealthOperators(dashboardData.healthOperators);
        setDoctors(dashboardData.doctors);
        setSecretaries(dashboardData.secretaries);
        setPatients(dashboardData.patients);
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  }, [sessionToken]);

  useEffect(() => {
    setLoading(true);
    const formatedInitalDate = `${initialDate.getFullYear()}-${(
      "0" +
      (initialDate.getMonth() + 1)
    ).slice(-2)}-${("0" + initialDate.getDate()).slice(-2)}`;
    const formatedFinalDate = `${finalDate.getFullYear()}-${(
      "0" +
      (finalDate.getMonth() + 1)
    ).slice(-2)}-${("0" + finalDate.getDate()).slice(-2)}`;
    Api.rest({
      method: "get",
      route: `administrator/dashboard/info?startDate=${formatedInitalDate}&finishDate=${formatedFinalDate}`,
      headers: { authorization: sessionToken },
    })
      .then((res) => {
        setLoading(false);
        setListInfoDashboard(res);
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  }, [finalDate, initialDate, sessionToken]);

  const formatDate = (date) => {
    const day = date.split("-")[2];
    const month = date.split("-")[1];
    const year = date.split("-")[0];

    const formatedDate = `${day}-${month}-${year}`;
    return formatedDate;
  };

  return (
    <div className={classes.root}>
      {loading && <Loading size={80} />}
      {!loading && (
        <>
          {" "}
          <h1>Home</h1>
          <div className="cards-dashboard">
            <div className="card">
              <h2>Clínicas</h2>
              <span>Quantidade: {clinics}</span>
            </div>

            <div className="card">
              <h2>Operadoras de Saúde</h2>
              <span>Quantidade: {healthOperators}</span>
            </div>

            <div className="card">
              <h2>Médicos</h2>
              <span>Quantidade: {doctors}</span>
            </div>
            <div className="card">
              <h2>Secretárias</h2>
              <span>Quantidade: {secretaries}</span>
            </div>
            <div className="card">
              <h2>Pacientes</h2>
              <span>Quantidade: {patients}</span>
            </div>
          </div>
          <div className="header-dashboard">
            <h1>Dashboard</h1>
            <div>
              De:{" "}
              <DatePickerDialog
                value={initialDate}
                onChange={(data) => onChangeInitialDate(data)}
                maxDate={finalDate}
                maxDateMessage="A data inicial não pode ser maior que a data final"
              />
              Até:{" "}
              <DatePickerDialog
                onChange={(data) => onChangeFinalDate(data)}
                value={finalDate}
                minDate={initialDate}
                minDateMessage="A data final não pode ser menor que a data inicial"
              />
            </div>

            <ButtonStyled
              label="Exportar para CSV"
              color="secondary"
              onClick={() => {
                const formatedInitalDate = `${initialDate.getFullYear()}-${(
                  "0" +
                  (initialDate.getMonth() + 1)
                ).slice(-2)}-${("0" + initialDate.getDate()).slice(-2)}`;
                const formatedFinalDate = `${finalDate.getFullYear()}-${(
                  "0" +
                  (finalDate.getMonth() + 1)
                ).slice(-2)}-${("0" + finalDate.getDate()).slice(-2)}`;
                window.open(
                  `${process.env.REACT_APP_API_URL}/administrator/dashboard/csv?startDate=${formatedInitalDate}&finishDate=${formatedFinalDate}`,
                  "_blank"
                );
              }}
            />
          </div>
          <div className="list-dashboard">
            {listInfoDashboard.length === 0 && (
              <Alert severity="info">
                Não foi encontrado nenhuma informação no período informado.
              </Alert>
            )}
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID Consulta</TableCell>
                    <TableCell>Tipo Consulta</TableCell>
                    <TableCell>Data Inicio Atendimento</TableCell>
                    <TableCell>Hora Inicio Atendimento</TableCell>
                    <TableCell>Data Fim Atendimento</TableCell>
                    <TableCell>Hora Fim Atendimento</TableCell>
                    <TableCell>ID Medico</TableCell>
                    <TableCell>Nome Medico</TableCell>
                    <TableCell>ID Clinica</TableCell>
                    <TableCell>Nome Clinica</TableCell>
                    <TableCell>ID Paciente</TableCell>
                    <TableCell>Nome Paciente</TableCell>
                    <TableCell>ID Operadora</TableCell>
                    <TableCell>Nome Operadora</TableCell>
                    <TableCell>Modalidade</TableCell>
                    <TableCell>Valor Consulta BRL</TableCell>
                    <TableCell>Valor Taxa Serviço</TableCell>
                    <TableCell>ID Especialidade</TableCell>
                    <TableCell>Especialidade</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listInfoDashboard.map((infoService, index) => (
                    <TableRow key={index}>
                      <TableCell>{infoService["ID Consulta"]}</TableCell>
                      <TableCell>{infoService["Tipo Consulta"]}</TableCell>
                      <TableCell>
                        {formatDate(infoService["Data Inicio Atendimento"])}
                      </TableCell>
                      <TableCell>
                        {infoService["Hora Inicio Atendimento"]}
                      </TableCell>
                      <TableCell>
                        {formatDate(infoService["Data Fim Atendimento"])}
                      </TableCell>
                      <TableCell>
                        {infoService["Hora Fim Atendimento"]}
                      </TableCell>
                      <TableCell>{infoService["ID Medico"]}</TableCell>
                      <TableCell>{infoService["Nome Medico"]}</TableCell>
                      <TableCell>{infoService["ID Clinica"]}</TableCell>
                      <TableCell>{infoService["Nome Clinica"]}</TableCell>
                      <TableCell>{infoService["ID Paciente"]}</TableCell>
                      <TableCell>{infoService["Nome Paciente"]}</TableCell>
                      <TableCell>{infoService["ID Operadora"]}</TableCell>
                      <TableCell>{infoService["Nome Operadora"]}</TableCell>
                      <TableCell>{infoService["Modalidade"]}</TableCell>
                      <TableCell>{infoService["Valor Consulta BRL"]}</TableCell>
                      <TableCell>{infoService["Valor Taxa Servico"]}</TableCell>
                      <TableCell>{infoService["ID Especialidade"]}</TableCell>
                      <TableCell>{infoService["Especialidade"]}</TableCell>
                      <TableCell>{infoService["Status"]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
}

export default Home;
