import React, { useState, useEffect } from "react";
import API from "./../../api";

import Loading from "../../common/circular-progress";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "./../../common/store/actions/user";

import logo from "../../assets/images/login/logo.svg";
import InputPassword from "../../common/input-password";
import Input from "../../common/input";
import Topic from "../../common/topic";
import Button from "../../common/button";

import { titleLogin } from "./style";

export default () => {
  const classesTitleLogin = titleLogin();

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgError, setMsgError] = useState("");
  const history = useHistory();

  const dispatch = useDispatch();
  const token = useSelector((state) => state.User.sessionToken);

  useEffect(() => {
    if (token) history.push("/");
  }, [token, history]);

  const handleSubmit = () => {
    if (email === "" || password === "") {
      setMsgError("Os campos Email e Senha precisam ser preenchidos");
      setLoading(false);
      return;
    }

    setLoading(true);
    setMsgError("");

    return API.graphql({
      body: {
        query: `
        mutation($input: LoginAdminInput!) {
          loginAdmin(input: $input) {
            user {
              id
              email
              name
            }
            sessionToken
          }    
        }
        `,
        variables: {
          input: {
            email,
            password,
          },
        },
      },
    })
      .then((res) => {
        const { errors, data } = res;
        if (errors) {
          setMsgError("Email ou Senha inválidos");
          setLoading(false);
          return;
        }
        const {
          loginAdmin: { user, sessionToken },
        } = data;
        dispatch(UserActions.setToken(sessionToken));
        dispatch(UserActions.setUserData(user));
        setLoading(false);
      })
      .catch(() => {
        setMsgError(
          "Houve um erro interno ao processar, por favor, tente novamente mais tarde"
        );
        setLoading(false);
      });
  };

  return (
    <div className="screen">
      {loading && <Loading size={80} />}
      <Hidden smDown>
        <div className="sidebar-image">
          <img src={logo} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className="content">
        <Topic
          classesNameCol={classesTitleLogin.root}
          title="Administrador - Vida Connect"
          classesNameSubtitle="text-black"
        />

        <Grid container>
          <form>
            <Grid item className="space-above-item-less">
              <Input
                name="input-email-login"
                datatest="input-email-login"
                type="email"
                label="Email"
                disabled={loading}
                width="360px"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item className="space-above-item-less">
              <InputPassword
                name="input-password-login"
                datatest="input-password-login"
                label="Senha"
                disabled={loading}
                width="360px"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={msgError}
              />
            </Grid>
            <Grid item className="forgot-password">
              <br />
              <a href="/forgotPassword">
                <small>ESQUECI MINHA SENHA</small>
              </a>
            </Grid>
            <Grid item className="space-above-item">
              <Button
                name="button-enter-login"
                datatest="button-enter-login"
                type="submit"
                width="170px"
                onClick={handleSubmit}
                disabled={loading}
              >
                Entrar
              </Button>
            </Grid>
          </form>
        </Grid>
      </div>
    </div>
  );
};
