export function setToken(token) {
  localStorage.setItem('token', token)
  return {
    type: "SET_TOKEN",
    payload: token,
  };
}

export function setUserData(userData) {
  return {
    type: "SET_USER_DATA",
    payload: userData,
  };
}

export function clearUserData() {
  localStorage.removeItem('token')
  return {
    type: "CLEAR_USER_DATA"
  };
}
