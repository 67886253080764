import React from "react";
import { HealthOperators } from "../components/healthOperators";
import { ContentItem } from "../components/dialogContentItem";
import { BankData } from "../components/bankData";
import { Attachments } from "../components/attachments";
import { Addresses } from "../components/addresses";
import Api from "../../../api";

export const dialogContentItem = (
    { title, text, styles, xs, conditional },
    index
  ) => {
    return (
      <ContentItem
      key={index}
      title={title}
      text={text}
      styles={styles}
      xs={xs}
      conditional={conditional}
      id={index}
      />
    )
 
  };

export const dialogContentBankData = ({ bank, agency, account }) => {
    return (
      <BankData
      bank={bank}
      agency={agency}
      account={account}
      />
    )
  }

export const dialogContentHealthOperators = (arrayHealthOperators) => {
    return (<HealthOperators
      arrayHealthOperators={arrayHealthOperators}
      />)
  }

export const dialogContentAttachments = (array) => {
   return ( <Attachments
    attachments={array}/>)
  };

export const dialogContentAddresses = (addresses) => {
      return (
        <Addresses
        addresses={addresses}/>
      )
  }
  
export const setSnackBarOpen = (message,stateSnackBar, setStateSnackBar, color = "error") => {
    setStateSnackBar({
        ...stateSnackBar,
        open: true,
        color: color,
        message: message
      });
   }

export const listUsers = (
    rows = `
    id
    name
    type
    createdAt
    status`,
    input = {
      limit: 10,
      page: 1,
      status: "",
    },
    sessionToken
  ) => {
    //const sessionToken = useSelector((state) => state.User.sessionToken);
    return Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
        query($input: ListUsersPendingApprovalInput) {
          listUsersPendingApproval(input: $input) {
            count
            rows {
              ${rows}
            }
          }
        }
      `,
        variables: {
          input: input,
        },
      },
    });
  };


  export const types = {
    PROVIDER_DOCTOR: "Médico",
    PROVIDER_SECRETARY: "Secretária",
    COMPANY_CLINIC: "Clínica",
    COMPANY_HEALTH_OPERATOR: "Operadora de Saúde",
    COMPANY_HOSPITAL: "Hospital",
    COMPANY_LABORATORY: "Laboratório",
    PROVIDER_OTHER_PROFESSIONALS: "Outros Profissionais"
  };

  export const createInput = (limit= 1, page =1, row) => {
    return {
      limit: limit,
      page: page,
      userId: row.id,
    };
}

export const mapHealthOperators = (healthOperatorsArray) => {
  let healthOperators = [];
  if (Array.isArray(healthOperatorsArray)) {
    healthOperators = healthOperatorsArray.map(
      (healthOperator) => healthOperator.plans.healthOperator
    );
  }

  return healthOperators
}