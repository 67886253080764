import React, { useState } from "react";
import Api from "../../../../api";

import Modal from "../../../../components/Modal";

import { makeStyles } from "@material-ui/core/styles";

import Loading from "../../../../common/circular-progress";

import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";

const useStyles = makeStyles({
  root: {
    width: "499px",
  },
});

export default (props) => {
  const classes = useStyles();

  const {
    infoUserAdmin,
    openEditModal,
    setOpenEditModal,
    sessionToken,
    setStateSnackBar,
  } = props;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(infoUserAdmin.name || "");
  const [nameError, setNameError] = useState({ error: false, typeError: "" });

  const editUser = () => {
    setLoading(true);
    if (empty(name).error) {
      setNameError(empty(name));
      setLoading(false);
    } else {
      const userAdminId = infoUserAdmin.id;
      Api.graphql({
        headers: { authorization: `Bearer ${sessionToken}` },
        body: {
          query: `
            mutation{
              editUserAdmin(userAdminName:"${name}", userAdminId:"${userAdminId}")          
            }
          `,
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.errors) {
            setStateSnackBar((state) => ({
              ...state,
              open: true,
              color: "error",
              message: res.errors[0].message,
            }));
          } else {
            setStateSnackBar((state) => ({
              ...state,
              open: true,
              color: "success",
              message: "Usuário alterado com sucesso!",
            }));
            props.getListAdmin();
            setOpenEditModal(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "error",
            message:
              "Houve um erro ao tentar alterar o usuário, por favor, tente novamente mais tarde ",
          }));
          console.log(err);
        });
    }
  };
  const body = () => (
    <div className={classes.root}>
      {loading && (
        <Loading size={80} position="absolute" height="30vh" width="30vw" />
      )}
      <Input
        disabled={loading}
        name="input-name-invite-admin"
        datatest="input-name-invite-admin"
        type="text"
        label="Nome"
        width="100%"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => setNameError(empty(name))}
        error={nameError.typeError}
      />
      <Input
        disabled
        name="input-email-invite-admin"
        datatest="input-email-invite-admin"
        type="email"
        label="Email"
        width="100%"
        value={infoUserAdmin.email}
      />
    </div>
  );

  return (
    <Modal
      open={openEditModal}
      title="Editar Usuário"
      body={body()}
      labelConfirm="Confirmar"
      onClose={() => setOpenEditModal(false)}
      onSubmit={() => editUser()}
      loading={loading}
    />
  );
};
