
import React from "react";
import Grid from "@material-ui/core/Grid";
import { dialogContentItem, listUsers,dialogContentHealthOperators, dialogContentBankData, 
        dialogContentAttachments, dialogContentAddresses, mapHealthOperators, createInput } from "../utils";
import { types } from "../utils";
import { setSnackBarOpen } from "../utils";
import { ApprovalRadio } from "../../components/ApprovalRadio";
import cpfMask from "../../../../common/mask/cpf";


export const dialogProfessional = ({row, setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => {
    const rows = getProfessionalsQuery()
    const input = createInput(1, 1, row)
    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
          return;
        }
        const row = data.listUsersPendingApproval.rows[0];
      
        return getModalInfo(row, setToApprove)
        
      })
      .catch((err) => {
        console.log(err);
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
      });
  };

  const getProfessionalsQuery = () => {
    return `
    id
    name
    email
    cpf
    phone
    status
    type
    provider {
      id
      unit {
        id
        cnes
        country
        zipCode
        address
        number
        complement
        neighborhood
        district
        city
        phone
        whatsapp
      } 
      providerHasSpecialities {
        id
        active
        providerId
        specialityId
        speciality {
          id
          name
          group
          description
          requireRqe
        }
        rqe
        rqeAttachment
      } 
      professionalHasProvider {
        id
        active
        professionId
        profession {
          id
          active
          profession
          doctorPrescription
          doctorPrescriptionSpecial
          doctorPrescriptionAntimicrobials
          sickNote
          examRequest
          medicalReport
        }
        professionalCouncilId
        professionalCouncil {
          id
          active
          name
        }
        professionalDocument
        uf
        bank
        agency
        account
        careModality
        professionalId
        proofResidence
        certificate
        numberOfInstallments
        minimumInstallmentAmount
        termsOfAgreement
        photo
        aboutMe
        experience
        treatMedicalConditions
        background
        lattes
      }  
    }
    createdAt
  `;
  }

  const getModalInfo = (row, setToApprove) => {
    const specialityOptional = setSpecialities(row)
        const contentItens = getContentItems(row, specialityOptional)
        let healthOperators = mapHealthOperators(row.provider.providerHasPlans)
        const { bank, agency, account } = row.provider.professionalHasProvider;
        const attachments = getAttachments(row.provider.professionalHasProvider)

        return (
          <Grid container>
            {contentItens.map((i, index) =>
              dialogContentItem(i, `${row.id}-${index}`)
            )}
            {healthOperators.length > 0 &&
              dialogContentHealthOperators(healthOperators)}
            {dialogContentBankData({ bank, agency, account })} 
            {dialogContentAttachments(attachments)}
            {dialogContentAddresses(row.provider.unit)}

            <ApprovalRadio
          row={row}
          setToApprove={setToApprove}
           /> 

            
          </Grid>
        );
  }

  const setSpecialities = (row) => {
    let specialityOptional = {
      condition: row.provider.providerHasSpecialities.length > 1,
      text: "",
      rqe: "",
      link: "",
    };
    if (specialityOptional.condition) {
      specialityOptional = {
        ...specialityOptional,
        text: row.provider.providerHasSpecialities[1].speciality.name,
      };
    }

    if (specialityOptional.condition) {
      specialityOptional = {
        ...specialityOptional,
      };
    }

    return specialityOptional
  }

  const getContentItems = (row, specialityOptional) => {
    
    let specialities = [
      { title: "Tipo do prestador", text: `${types[row.type]} - ${row.provider.professionalHasProvider.profession.profession}` },
      { title: "Nome", text: row.name },
      { title: "CPF", text: cpfMask(row.cpf) },
      { title: "Email", text: row.email },
      { title: "Telefone", text: row.phone },
      { title: "Documento Profissional", text: row.provider.professionalHasProvider.professionalDocument },
      {
        title: "Especialidade 1",
        text: row.provider.providerHasSpecialities[0].speciality.name,
      },
    ];
    
    if(specialityOptional.condition){
     specialities = [...specialities, {
        title: "Especialidade 2",
        text: specialityOptional.text,
        conditional: specialityOptional.condition,
      }]
    }
    
    return specialities
  }

  const getAttachments = (professionalHasProvider) => {
    return [
      {
        title: "1. Identidade profissional (Frente e verso)",
        link: `/provider/download/${professionalHasProvider.professionalId}`,
      },
      {
        title: "2.	Certificado",
        link: `/provider/download/${professionalHasProvider.certificate}`
      },
      {
        title: "3.	Comprovante de endereço",
        link: `/provider/download/${professionalHasProvider.proofResidence}`,
      }
    ];
  }