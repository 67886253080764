import firebase from 'firebase'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_ENVIRONMENT,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
}

const mockAnalytics = { analytics: () => ({ setUserId: () => true, setUserProperties: () => true, logEvent: () => true }) }

const initialize = REACT_APP_ENVIRONMENT === 'production' ? firebase.initializeApp(firebaseConfig) : mockAnalytics

export default initialize
