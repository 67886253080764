import React from "react";

export default (props) => (
  <div className={`${props.classesNameCol}`}>
    <h1 className={`title`}>{props.title}</h1>

    <h2 className={`subtitle ${props.classesNameSubtitle}`}>
      {props.subtitle}
    </h2>
    {props.warning ? (
      <div>
        <span className={"error-input"}>{props.warning}</span>
      </div>
    ) : null}
  </div>
);
