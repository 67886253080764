import React from "react";
import ButtonStyled from "../../../components/ButtonStyled";
export function DownloadButton({link}){
    return (
        <ButtonStyled
        key={link}
        size="small"
        label="Download"
        color="secondary"
        styles={{ marginLeft: 5 }}
        onClick={() =>
          window.open(`${process.env.REACT_APP_API_URL}${link}`, "_blank")
        }
      />
    )
}