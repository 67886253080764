import React, { useEffect, useState, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Loading from "../../common/circular-progress";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import DatePickerDialog from "./../../components/DatePickerDialog";
import moment from "moment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TabPanel from "./../../components/TabPanel";
import DataTable from "./../../components/DataTable";
import SnackBar from "./../../common/snackbar";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import { parseISO } from "date-fns";
import { ConfirmApproval } from "./modals/confirmApproval";
import { Details } from "./modals/details";
import { DisapprovalDescription } from "./modals/disapprovalDescription";
import { dialogHospital, dialogLaboratory, dialogClinic } from "./dialogs/establishments";
import { dialogDoctor } from "./dialogs/providers/doctors";
import { dialogProfessional } from "./dialogs/providers/professionals";
import { dialogHealthOperator } from "./dialogs/establishments/healthOperators";
import { listUsers, types } from "./dialogs/utils";

const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: 40,
    flexGrow: 1,
  },
  tabs: {
    marginTop: 30,
  },
  tabTitle: {
    ...theme.font.title,
    fontSize: 14,
  },
  title: {
    ...theme.font.title,
    flexGrow: 1,
  },
  filter: {
    ...theme.font.title,
    fontWeight: "normal",
    fontSize: 21,
    flexGrow: 1,
  },
  filterButton: {
    backgroundColor: "#fff",
    color: "#6B6C7E",
    textTransform: "none",
    border: "1px solid #CDCED9",
  },
  filterMenuItem: {
    width: 120,
  },
  filterButtonMenuItem: {
    ...theme.font.title,
    fontSize: 12,
    color: "#272833",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: "#fff",
  },
}));

function Approvals() {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const theme = useTheme();
  const classes = useStyles();
  const sessionToken = useSelector((state) => state.User.sessionToken);
  const [initialDate, setInitialDate] = useState(
    moment().subtract(15, "days").format("YYYY-MM-DD")
  );
  const [finalDate, setFinalDate] = useState(parseISO(moment().format("YYYY-MM-DD")));
  const [valueTab, setValueTab] = useState(0);
  const [rowSelected, setRowSelected] = useState(undefined);
  const [toApprove, setToApprove] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [modalContent, setModalContent] = useState(undefined);
  const [rows, setRows] = useState([]);
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });
  const [tablePage, setTablePage] = useState(0);
  const [tableLimit, setTableLimit] = useState(10);
  const [tableCount, setTableCount] = useState(rows.length);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const status = [
    { name: "Aprovado", value: "OK" },
    { name: "Pendente", value: "PENDING_APPROVAL" },
    { name: "Reprovado", value: "DENIED" },
  ];
  const [statusSelected, setStatus] = useState(status[1].value);
  const [name, setName] = useState('')

  const onChangeSelectStatus = (val) => {
    setStatus(val.target.value);
  };

  const statusToTable = {
    OK: { name: "Aprovado", class: "badgeOk" },
    PENDING_APPROVAL: { name: "Pendente", class: "badgePendingApproval" },
    DENIED: { name: "Reprovado", color: "#FEEFEF", class: "badgeDenied" },
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const itens = () => {
    return status.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.name}
      </MenuItem>
    ));
  };

  const fetchApiData = () => {
    let formatedInitalDate = initialDate;
    let formatedFinalDate = finalDate;

    if (initialDate.length !== 10) {
      formatedInitalDate = `${initialDate.getFullYear()}-${(
        "0" +
        (initialDate.getMonth() + 1)
      ).slice(-2)}-${("0" + initialDate.getDate()).slice(-2)}`;
    }
    if (finalDate.length !== 10) {
      formatedFinalDate = `${finalDate.getFullYear()}-${(
        "0" +
        (finalDate.getMonth() + 1)
      ).slice(-2)}-${("0" + finalDate.getDate()).slice(-2)}`;
    }
    setFetchingData(true);
    const rows = `
    id
    name
    type
    createdAt
    status`;
    const input = {
      limit: tableLimit,
      page: tablePage + 1,
      status: statusSelected,
      initialDate: formatedInitalDate,
      finalDate: formatedFinalDate,
      name,
      type: ['COMPANY_CLINIC','COMPANY_HEALTH_OPERATOR','PROVIDER_DOCTOR','COMPANY_HOSPITAL', 'PROVIDER_OTHER_PROFESSIONALS', 'COMPANY_LABORATORY']
    };
    return listUsers(rows, input, sessionToken)
      .then((res) => {
        if (res.errors) {
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message: "Erro",
          });
          setFetchingData(false);
          return;
        }

        setRows(res.data.listUsersPendingApproval.rows);
        setTableCount(res.data.listUsersPendingApproval.count);
        setFetchingData(false);
      })
      .catch((err) => {
        setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",
        });
        setFetchingData(false);
      });
  };

  useEffect(() => {
    fetchApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelected, tableLimit, tablePage, initialDate, finalDate, name]);

  const onChangeInitialDate = (date) => {
    setInitialDate(date);
  };

  const onChangeFinalDate = (date) => {
    setFinalDate(date);
  };

  const handleOnClickRow = async (row) => {
    if (!row) return;
    
    setOpenModal(true);
    const params = openDialogParams(row)
    switch (row.type) {
      case "PROVIDER_DOCTOR":
       return setModalContent(await dialogDoctor(params));

      case "COMPANY_CLINIC":
        return setModalContent(await dialogClinic(params));

      case "COMPANY_HEALTH_OPERATOR":
        return setModalContent(await dialogHealthOperator(params));

      case "COMPANY_HOSPITAL":
        return setModalContent(await dialogHospital(params));

      case "COMPANY_LABORATORY":
        return setModalContent(await dialogLaboratory(params));
      
      case "PROVIDER_OTHER_PROFESSIONALS":
        return setModalContent(await dialogProfessional(params));

      default:
        return setStateSnackBar({
          ...stateSnackBar,
          open: true,
          message: "Houve um erro ao tentar abrir os detalhes do prestador",
        });
    }
  };

  const openDialogParams = (row) => {
    return {
      row,
      setToApprove,
      stateSnackBar, 
      setStateSnackBar, 
      sessionToken
    }
  }

  const handleCloseModal = (fnToClose) => {
    fnToClose(false);
   // setToApprove(undefined);
  };

  const app = () => (
    <Fragment>
      <Container maxWidth={false} style={{ paddingLeft: 40 }}>
        <div className={classes.top}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
          >
            <Grid
              item
              sm={2}
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="baseline"
            >
              <Typography variant="h1" className={classes.title}>
                Aprovações
              </Typography>
            </Grid>

            <Grid
              item
              sm={8}
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="baseline"
            >
              <Grid style={{ paddingRight: 10 }}>
                <Typography variant="h1" className={classes.filter}>
                  De
                </Typography>
              </Grid>
              <DatePickerDialog
                disableFuture
                onChange={(data) => onChangeInitialDate(data)}
                value={initialDate}
                maxDate={finalDate}
                maxDateMessage="A data inicial não pode ser maior que a data final"
              />

              <Grid style={{ paddingRight: 10, marginLeft: 20 }}>
                <Typography variant="h1" className={classes.filter}>
                  Até
                </Typography>
              </Grid>
              <DatePickerDialog
                disableFuture
                onChange={(data) => onChangeFinalDate(data)}
                value={finalDate}
                minDate={initialDate}
                minDateMessage="A data final não pode ser menor que a data final"
              />
            </Grid>

            <Grid
              item
              sm={2}
              xs={12}
              container
              direction="row"
              justify="center"
              alignItems="baseline"
            >
              <Button
                disableElevation
                size="large"
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                className={classes.filterButton}
                elevation={0}
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
              >
                Filtros
              </Button>
              <Menu
                style={{ marginTop: 50 }}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem className={classes.filterMenuItem}>
                  <Typography
                    variant="inherit"
                    noWrap
                    className={classes.filterButtonMenuItem}
                  >
                    Status
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Selecionar
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Selecionar"
                      value={statusSelected}
                      defaultValue={statusSelected}
                      autoWidth={true}
                      onChange={onChangeSelectStatus}
                    >
                      {itens()}
                    </Select>
                  </FormControl>
                </MenuItem>
                <MenuItem 
                  className={classes.filterMenuItem}
                  onKeyDown={e => e.stopPropagation()}
                >
                  <Typography
                    variant="inherit"
                    noWrap
                    className={classes.filterButtonMenuItem}
                  >
                    Nome
                  </Typography>
                </MenuItem>
                <MenuItem
                  onKeyDown={e => e.stopPropagation()}
                >
                  <TextField
                      variant="outlined"
                      value={name}
                      onChange={(evt) => {setName(evt.target.value)} }
                    >
                  </TextField>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </div>

        <div className={classes.tabs}>
          <AppBar position="static" color="transparent" elevation={0}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="inherit"
              aria-label="full width tabs example"
            >
              <Tab
                className={classes.tabTitle}
                label="Prestadores"
                {...a11yProps(0)}
              />
              <Tab
                disabled
                className={classes.tabTitle}
                label="Outros serviços"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={valueTab} index={0} dir={theme.direction}>
            {fetchingData ? (
              <Loading alignItems="flex-start" position="inherit" />
            ) : (
              <DataTable
                header={[
                  "Nome",
                  "Tipo do cadastro",
                  "Data do cadastro",
                  "Status",
                  "Ação",
                ]}
                rows={rows}
                loading={false}
                onClickRow={(row) => {
                  setRowSelected(row);
                  handleOnClickRow(row);
                }}
                types={types}
                status={statusToTable}
                page={tablePage}
                limit={tableLimit}
                count={tableCount}
                onChangePage={(value) => setTablePage(value)}
                onChangeLimit={(value) => setTableLimit(value)}
              />
            )}
          </TabPanel>
          <TabPanel value={valueTab} index={1} dir={theme.direction}>
            Outros serviços
          </TabPanel>
        </div>
      </Container>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />

      {openModal &&
       <Details
       openModal={openModal}
       setOpenModal={setOpenModal}
       modalContent={modalContent}
       toApprove={toApprove}
       rowSelected={rowSelected}
       handleCloseModal={handleCloseModal} 
       stateSnackBar={stateSnackBar}
       setStateSnackBar={setStateSnackBar}
       setOpenDescriptionModal={setOpenDescriptionModal}
       setOpenConfirmModal={setOpenConfirmModal}
       />
      }

      {openDescriptionModal &&  <DisapprovalDescription
       openDescriptionModal={openDescriptionModal}
       handleCloseModal={handleCloseModal}
       setOpenDescriptionModal={setOpenDescriptionModal}
       stateSnackBar={stateSnackBar}
       setStateSnackBar={setStateSnackBar}
       rowSelected={rowSelected}
       setOpenModal={setOpenModal}
       setFetchingData={setFetchingData}
       rows={rows}
       setRows={setRows}
       setLoadingConfirm={setLoadingConfirm}
      />}

      {openConfirmModal &&  
          <ConfirmApproval
          openConfirmModal={openConfirmModal}
          handleCloseModal={handleCloseModal}
          setOpenConfirmModal={setOpenConfirmModal}
          stateSnackBar={stateSnackBar}
          setStateSnackBar={setStateSnackBar}
          rowSelected={rowSelected}
          setOpenModal={setOpenModal}
          setFetchingData={setFetchingData}
          rows={rows}
          setRows={setRows}
          setLoadingConfirm={setLoadingConfirm}
          />
        }
    
        {loadingConfirm && (
        <Backdrop className={classes.backdrop} open={loadingConfirm}>
          <Loading size={80} />
        </Backdrop>
      )}
    </Fragment>
  );

  return <Fragment>{app()}</Fragment>;
}

export default Approvals;
