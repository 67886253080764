import React from "react";
import Grid from "@material-ui/core/Grid";
import { dialogContentItem, listUsers,dialogContentHealthOperators, dialogContentBankData, 
        dialogContentAttachments, dialogContentAddresses, mapHealthOperators, createInput } from "../utils";
import { types } from "../utils";
import { setSnackBarOpen } from "../utils";
import { ApprovalRadio } from "../../components/ApprovalRadio";
import cpfMask from "../../../../common/mask/cpf";

 export const dialogDoctor = ({row,  setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => { 
    const rows = getQuery()
    const input = createInput(1, 1, row)
    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde", stateSnackBar, setStateSnackBar)
          return;
        }
        
        const row = data.listUsersPendingApproval.rows[0];
        return getModalInfo(row, setToApprove)
      })
      .catch((err) => {
        console.log(err);
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde", stateSnackBar, setStateSnackBar)
      });
  }; 

  const getQuery = () => {
   return `
      id
      name
      email
      cpf
      phone
      status
      type
      provider {
        id
        providerHasPlans{
          plans{
            healthOperator{
              fantasyName
              companyName
            }
          }
        }
        doctor {
          id
          crm
          uf
          bank
          agency
          account
          careModality
          professionalId
          proofResidence
          numberOfInstallments
          minimumInstallmentAmount
          termsOfAgreement
          photo
          aboutMe
          experience
          treatMedicalConditions
          background
          lattes
        }
        providerHasSpecialities {
          id
          active
          providerId
          specialityId
          speciality {
            id
            name
            group
            description
            requireRqe
          }
          rqe
          rqeAttachment
        } 
        unit {
          id
          cnes
          country
          zipCode
          address
          number
          complement
          neighborhood
          district
          city
          phone
          whatsapp
        }
      }
      createdAt
    `;
  }

  const getModalInfo = (row, setToApprove) => {

    const {specialityOptional, specialityOptionalTwo} = setSpecialities(row)
    const contentItens =  getContentItems(row, specialityOptional, specialityOptionalTwo)
    let healthOperators = mapHealthOperators(row.provider.providerHasPlans)
    const { bank, agency, account } = row.provider.doctor;
    const attachments = getAttachments(row.provider)
    
    return (
      <Grid container>
        {contentItens.map((i, index) =>
          dialogContentItem(i, `${row.id}-${index}`)
        )}
        {healthOperators.length > 0 &&
          dialogContentHealthOperators(healthOperators)}
        {dialogContentBankData({ bank, agency, account })}
        {dialogContentAttachments(attachments)}
        {dialogContentAddresses(row.provider.unit)} 

        <ApprovalRadio
      row={row}
      setToApprove={setToApprove}
       /> 
      
      </Grid>
    );
  }

  const setSpecialities = (row) => {
    let specialityOptional = {
      condition: row.provider.providerHasSpecialities.filter(i => Boolean(i.rqe)).length === 2,
      text: "",
      rqe: "",
      link: "",
    };
    if (specialityOptional.condition) {
      specialityOptional = {
        ...specialityOptional,
        text: row.provider.providerHasSpecialities.filter(i => Boolean(i.rqe))[1]?.speciality.name,
      };
    }

    let specialityOptionalTwo = {
      condition: row.provider.providerHasSpecialities.filter(i => !Boolean(i.rqe)).length === 1,
      text: "",
      rqe: "",
      link: "",
    };
    if (specialityOptionalTwo.condition) {
      specialityOptionalTwo = {
        ...specialityOptionalTwo,
        text: row.provider.providerHasSpecialities.filter(i => !Boolean(i.rqe))[0]?.speciality.name,
      };
    }

     if (specialityOptional.condition) {
      specialityOptional = {
        ...specialityOptional,
        rqe: row.provider.providerHasSpecialities[1].rqe,
        link: `/provider/download/${row.provider.providerHasSpecialities[1].rqeAttachment}`,
      };
    }

    return {specialityOptional, specialityOptionalTwo}
  }

  const getContentItems = (row, specialityOptional, specialityOptionalTwo) => {

      let specialities = [
        { title: "Tipo do prestador", text: types[row.type] },
        { title: "Nome", text: row.name },
        { title: "CPF", text: cpfMask(row.cpf) },
        { title: "Email", text: row.email },
        { title: "Telefone", text: row.phone },
        {
          title: "CRM",
          text: `${row.provider.doctor.crm}-${row.provider.doctor.uf}`,
        },
        {
          title: "Especialidade 1",
          text: row.provider.providerHasSpecialities.filter(i => Boolean(i.rqe))[0]?.speciality.name,
        }
      ]

      if(specialityOptional.condition){
        specialities = [...specialities, {
          title: "Especialidade 2",
          text: specialityOptional.text,
          conditional: specialityOptional.condition,
        }]
       }

       if(specialityOptionalTwo.condition){
        specialities = [...specialities, {
          title: `Especialidade ${specialityOptional.condition ? '3' : '2'}`,
          text: specialityOptionalTwo.text,
          conditional: specialityOptionalTwo.condition,
        }]
       }

       return specialities
  }

  const getAttachments = (provider) => {
    const doctor = provider.doctor
      let attachments = [
        {
          title: "1. Identidade profissional (Frente e verso)",
          link: `/provider/download/${doctor.professionalId}`,
        },
        { title: "2.	Título(s) de especialidade(s) - (RQE)" },
      ];
      for (const speciality of provider.providerHasSpecialities) {
        if (!Boolean(speciality.rqe)) continue
        attachments.push({
          title: `RQE: ${speciality.rqe}`,
          link: `/provider/download/${speciality.rqeAttachment}`,
        });
      }
      attachments.push({
        title: "3.	Comprovante de endereço",
        link: `/provider/download/${doctor.proofResidence}`,
      });

      return attachments
  }