import React from "react"
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Modal from "./../../../components/Modal";
import Api from "../../../api";

export function ConfirmApproval(props){
  
    const {
         openConfirmModal,
         handleCloseModal,
         setOpenConfirmModal,
         stateSnackBar,
         setStateSnackBar,
         rowSelected,
         setOpenModal,
         setFetchingData,
         rows,
         setRows,
         setLoadingConfirm } = props
    const sessionToken = useSelector((state) => state.User.sessionToken);
  
    const confirmApprovedModal = () => {
        setLoadingConfirm(true);
        const input = createInputData()

        sendApprovalApi(input)
      };

   const createInputData = () => {
     const input = {
        userId: rowSelected.id,
        approved: true,
      };

      return input
   }
   
   const sendApprovalApi = async (input) => {
    return handleRequestAdminApprovals(input)
           .then((res) => {
                handleApprovalResponse(res)
            })
            .catch(() => {
                setSnackBarOpen("Houve um erro ao tentar aprovar este usuário. Por favor, tente novamente mais tarde")
                setCloseModals()
            });
   }

   const handleRequestAdminApprovals = async (input) => {
    return Api.graphql({
        headers: { authorization: `Bearer ${sessionToken}` },
        body: {
          query: `
            mutation($input: ApprovalsInput!) {
              adminApprovals(input: $input)
            }
          `,
          variables: { input },
        },
      })
   }

   const handleApprovalResponse = (res) => {
    const { errors } = res;
    if (errors) {  
      setSnackBarOpen("Houve um erro ao tentar aprovar este usuário. Por favor, tente novamente mais tarde")
      setCloseModals()
      return;
    }

    setSnackBarOpen("Usuário aprovado com sucesso!", "success")
    setCloseModals()
    updateRows();
   }

   const setSnackBarOpen = (message, color = "error") => {
    setStateSnackBar({
        ...stateSnackBar,
        open: true,
        color: color,
        message: message
      });
   }
   
   const setCloseModals = () => {
    setOpenConfirmModal(false);
    setOpenModal(false);
    setLoadingConfirm(false);
   }   

   const updateRows = () => {
    setFetchingData(true);
    setRows(rows.filter((row) => row.id !== rowSelected.id));
    setFetchingData(false);
   }

  return (
    <>
    <Modal
    title="Tem certeza que deseja aprovar este usuário?"
    open={openConfirmModal}
    body={
      <Typography color="error">
        Atenção! Se você confirmar, este usuário terá acesso à plataforma
        do Vida Connect. Tem certeza disso?
      </Typography>
    }
    onClose={() => handleCloseModal(setOpenConfirmModal)}
    onSubmit={confirmApprovedModal}
    labelConfirm="Sim"
    labelCancel="Não"
  />
  </>
  )
}