import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../api";

import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";
import Input from "../../../common/input";
import ButtonStyled from "../../../components/ButtonStyled";
import percentageMask from "../../../common/mask/percentage";
import SnackBar from "../../../common/snackbar";
import Loading from "../../../common/circular-progress";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100vh - 76px)",
    minHeight: "500px",
  },
  content: {
    padding: 30,
  },
  tax: {
    marginTop: "48px",
    display: "flex",
    width: "100%",
    "& div.taxes": {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      "& div.tax": {
        minWidth: "30%",
        display: "flex",
        flexDirection: "column",
        "& h2": {
          margin: "0 0 25px 0",
          fontSize: "20px",
          fontWeight: 500,
          color: "#272833",
        },
      },
      "& .MuiDivider-vertical": {
        marginRight: "10%",
      },
    },
  },
});

const footerStyle = makeStyles({
  root: {
    width: "100%",
    marginTop: "3vh",
    height: "83px",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    backgroundColor: "#FFF",
    "& button": {
      height: "40px",
      width: "120px",
      marginRight: "24px",
    },
  },
});

function Footer(props) {
  const classes = footerStyle();

  return (
    <div className={classes.root}>
      <ButtonStyled
        label="Salvar"
        color="primary"
        onClick={props.handleSaveTaxes}
        disabled={props.loading}
      />
    </div>
  );
}

function Values() {
  const classes = useStyles();

  const sessionToken = useSelector((state) => state.User.sessionToken);
  const [taxAppointmentProvider, setTaxAppointmentProvider] = useState(
    percentageMask(0)
  );
  const [taxAppointmentPatient, setTaxAppointmentPatient] = useState(
    percentageMask(0)
  );
  const [
    taxCancelAppointmentPatient,
    setTaxCancelAppointmentPatient,
  ] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  useEffect(() => {
    setLoading(true);
    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
            query{
              getTaxes{
                type
                value
              }
            }
          `,
      },
    })
      .then((res) => {
        setLoading(false);
        const taxes = res.data.getTaxes;
        setTaxAppointmentProvider(
          percentageMask(
            taxes.filter((tax) => tax.type === "APPOINTMENT_FEE_PROVIDER")[0]
              .value
          )
        );
        setTaxAppointmentPatient(
          percentageMask(
            taxes.filter((tax) => tax.type === "APPOINTMENT_FEE_PATIENT")[0]
              .value
          )
        );
        setTaxCancelAppointmentPatient(
          percentageMask(
            taxes.filter(
              (tax) => tax.type === "FEE_CANCEL_APPOINTMENT_PATIENT"
            )[0].value
          )
        );
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  }, [sessionToken]);

  const handleSaveTaxes = () => {
    setLoading(true);
    const input = [
      {
        type: "APPOINTMENT_FEE_PROVIDER",
        value: parseFloat(taxAppointmentProvider.replace(/\D/g, "")),
      },
      {
        type: "APPOINTMENT_FEE_PATIENT",
        value: parseFloat(taxAppointmentPatient.replace(/\D/g, "")),
      },
      {
        type: "FEE_CANCEL_APPOINTMENT_PATIENT",
        value: parseFloat(taxCancelAppointmentPatient.replace(/\D/g, "")),
      },
    ];

    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
            mutation updateTaxes($input:[UpdateTaxesInput!]!){
              updateTaxes(input: $input)
            }
          `,
        variables: { input },
      },
    })
      .then((res) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "success",
          message: "Taxas alteradas com sucesso! ",
        }));
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message:
            "Houve um erro ao tentar salvar as taxas, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.content}>
          <div>
            <h1>Valores</h1>
          </div>
          <div className={classes.tax}>
            {loading && <Loading size={80} height="40vh" width="60vw" />}
            <div className="taxes">
              <div className="tax">
                <h2>Taxa para Prestadores</h2>
                <Input
                  disabled={loading}
                  name="input-percentage-tax-provider-consultation"
                  datatest="input-percentage-tax-provider-consultation"
                  type="text"
                  label="Taxa sobre o valor da consulta"
                  width="265px"
                  value={percentageMask(taxAppointmentProvider)}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value.length < taxAppointmentProvider.length) {
                      value = value.slice(0, -1);
                    }
                    setTaxAppointmentProvider(percentageMask(value));
                  }}
                />
              </div>
              <Hidden smDown>
                <Divider orientation="vertical" />
              </Hidden>
              <Hidden xsUp>
                <Divider orientation="horizontal" />
              </Hidden>
              <div className="tax">
                <h2>Taxa para Pacientes</h2>

                <Input
                  disabled={loading}
                  name="input-percentage-tax-patient-consultation"
                  datatest="input-percentage-tax-patient-consultation"
                  type="text"
                  label="Taxa sobre o valor da consulta"
                  width="265px"
                  value={percentageMask(taxAppointmentPatient)}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value.length < taxAppointmentPatient.length) {
                      value = value.slice(0, -1);
                    }
                    setTaxAppointmentPatient(percentageMask(value));
                  }}
                />

                <Input
                  disabled={loading}
                  name="input-percentage-tax-patient-cancellation"
                  datatest="input-percentage-tax-patient-cancellation"
                  type="text"
                  label="Taxa de cancelamento"
                  width="265px"
                  value={percentageMask(taxCancelAppointmentPatient)}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value.length < taxCancelAppointmentPatient.length) {
                      value = value.slice(0, -1);
                    }
                    setTaxCancelAppointmentPatient(percentageMask(value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer handleSaveTaxes={handleSaveTaxes} loading={loading} />
        </div>
      </div>
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
}

export default () => {
  return (
    <>
      <ResponsiveDrawer component={Values} spacing={1} />
    </>
  );
};
