import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Skeleton from "@material-ui/lab/Skeleton";
import ButtonStyled from "./../ButtonStyled";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    width: 500,
  },
  skeletonTypograph: {
    marginTop: 25,
  },
}));

export default function Modal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open || false);
  const [showConfirmButton] = React.useState(
    props.showConfirmButton === undefined ? true : props.showConfirmButton
  );

  const handleClose = () => {
    setOpen(false);
    props.onClose(false);
  };

  const handleConfirm = () => {
    props.onSubmit(open);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const modalContent = () => {
    if (props.body) {
      return props.body;
    }
    return [...new Array(20)].map((i, index) => (
      <Skeleton key={index} className={classes.skeleton} />
    ));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        disableBackdropClick={props.disableBackdropClick ? true : false}
        disableEscapeKeyDown={props.disableEscapeKeyDown ? true : false}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            component={"div"}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          >
            {modalContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonStyled
            label={props.labelCancel || "Cancelar"}
            color="secondary"
            disabled={!props.body || props.body === "" || props.loading}
            onClick={handleClose}
          />
          {showConfirmButton && (
            <ButtonStyled
              label={props.labelConfirm || "Salvar"}
              disabled={!props.body || props.body === "" || props.loading}
              onClick={handleConfirm}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
