import axios from 'axios'

const apiURI = process.env.REACT_APP_API_URL

export default {
  graphql: ({
    body,
    headers = { authorization: null }
  }) => new Promise((resolve, reject) => {
    return axios.post(`${apiURI}/graphql`, body, { headers })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  }),

  rest: ({
    method,
    route = '/',
    body = {},
    headers = { authorization: null }
  }) => new Promise((resolve, reject) => {
    return axios[method](`${apiURI}/${route}`, body, { headers })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  })
}
