import { makeStyles } from "@material-ui/core/styles";

const titleLogin = makeStyles({
  root: {
    margin: "23.8vh 0 28px 0",
    "& h1": {
      fontSize: "2.28rem",
      fontWeight: "bold",
      color: "#000",
      margin: "0 0 6px 0",
    },
    "& h2": {
      fontSize: "1.28rem",
      fontWeight: "normal",
      color: "#393A4A",
      margin: 0,
    },
  },
});

export { titleLogin };
