import React from 'react'
import ResponsiveDrawer from '../../../components/ResponsiveDrawer'

function Indications() {
  return (
    <h1>Indicações</h1>
  )
}

export default () => {
  return (
    <ResponsiveDrawer component={Indications} />
  )
}
