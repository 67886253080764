import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation, NavLink } from "react-router-dom";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(-5),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperDesktop: {
    width: drawerWidth,
    marginTop: 75,
  },
  content: {
    flexGrow: 1,
    padding: (rest) => (rest.spacing ? rest.spacing : theme.spacing(3)),
  },
}));

function ResponsiveDrawer({ window, component, spacing, ...rest }) {
  const classes = useStyles({ spacing: spacing });
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const itens = [
    { text: "Administradores", pathname: `/administrators` },
    { text: "Valores", pathname: "/values" },
    { text: "Pacientes", pathname: "/patients" },
    //{ text: "Indicações", pathname: "/indications" },
    //{ text: "Campanhas", pathname: "/campaigns" },
  ];

  const drawer = (
    <div>
      <List style={{ marginTop: -8 }}>
        {itens.map((item, index) => (
          <NavLink key={item.pathname} to={`/platform${item.pathname}`}>
            <ListItem
              button
              style={{
                backgroundColor: pathname.includes(item.pathname)
                  ? theme.palette.drawerItemActive
                  : "transparent",
              }}
            >
              <ListItemText
                primary={item.text}
                style={{ fontWeight: 500, fontSize: 15 }}
              />
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Toolbar>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaperDesktop,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid component={component}>{rest.teste}</Grid>
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
