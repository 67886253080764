import React from "react";
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import "./global.css";

import Routes from "./routes";

import { Provider } from "react-redux";
import { store, persistor } from "./common/store";
import { PersistGate } from 'redux-persist/integration/react';

const palette = {
  primary: {
    main: "#DF9000",
  },
  secondary: {
    main: "#6B6C7E",
  },
  drawerItemActive: '#F9B233',
  fontPrimary: '#6B6C7E',
  backgroundMain: '#F7F8F9',
  avatarBackgroundColor: '#FFF8DE'
};

let theme = createMuiTheme({
  font: {
    title: {
      fontFamily: "Montserrat",
      color: '#272833',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: 25,
      lineHeight: '150%',
    }
  },
  typography: {
    fontFamily: "Montserrat , sans-serif",
    color: '#6B6C7E',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '150%',
  },
  palette,
  overrides: {
    MuiButton: {
      outlinedSecondary: {
        backgroundColor: '#fff',
        textTransform: 'none',
        color: palette.secondary.main,
      },
      outlinedPrimary: {
        backgroundColor: '#fff',
        textTransform: 'none',
        color: palette.primary.main,
        border: '1px solid',
      }
    },
  },
})
theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
