import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.avatarBackgroundColor,
    color: 'black',
    '&:focus, &:hover': {
      textDecoration: 'none'
    },
  }
}))

export default function Gravatar(props) {
  const classes = useStyles()

  const { username } = props

  const nameSplit = username.split(' ')

  if (nameSplit.length === 1) {
    return (
      <Avatar className={classes.root} >{(nameSplit[0].split('')[0]).toUpperCase()}</Avatar>
    )
  }

  return (
    <Avatar>{(nameSplit[0].split('')[0] + nameSplit[1].split('')[0]).toUpperCase()}</Avatar>
  )
}
