import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import pencil from "../../../assets/icons/pencil.svg";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";
import Api from "../../../api";
import Loading from "../../../common/circular-progress";
import SnackBar from "../../../common/snackbar";
import { Alert } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "../../../common/input";
import { format } from 'date-fns'
import telephoneMask from './../../../common/mask/telephone'
import Icon from '@material-ui/core/Icon';
import { green, red } from '@material-ui/core/colors';

import ModalEdit from './modal/edit'

const useStyles = makeStyles({
  root: {
    "& div.header": {
      margin: "15px 0px 32px 0px",
      display: "flex",
      justifyContent: "space-between",
      "& h1": {
        margin: 0,
      },
      "& .MuiButton-outlinedSizeSmall": {
        height: "32px",
        minWidth: "195px",
      },
    },
    "& div.list-admin": {
      "& img": {
        cursor: "pointer",
      },
      "& thead": {
        backgroundColor: "#F7F8F9",
        "& th": {
          color: "#6B6C7E",
          fontSize: "14px",
        },
      },
      "& tr": {
        height: "64px",
      },
      "& .MuiTableCell-footer": {
        backgroundColor: "#F7F8F9",
      },
    },
    "& div.filter": {
      float: "right"
    }
  },
  activeSuccess: {
    border: '1px solid #66bb6a',
    borderRadius: '30px',
    background: '#66bb6a',
    padding: 7,
    fontSize: 12,
    color: 'white'
  }
});

const userType = {
  PROVIDER_DOCTOR: 'Médico',
  PROVIDER_SECRETARY: 'Secretária',
  PROVIDER_HEALTH_OPERATOR_EMPLOYEE: 'Analista da operadora',
  COMPANY_CLINIC: 'Clínica',
  COMPANY_HEALTH_OPERATOR: 'Operadora de saúde',
  PATIENT: 'Paciente',
}

const typeMask = (type) => {
  return userType[type]
}

const maskActive = (active) => {
  if (Boolean(active)) {
    return (
      <Icon style={{ color: green[500] }}>check_circle_outline</Icon>
    )
  }

  return <Icon style={{ color: red[500] }}>highlight_off</Icon>
}


function Users() {
  const classes = useStyles();

  const sessionToken = useSelector((state) => state.User.sessionToken);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [name, setName] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false)
  const [user, setUser] = useState()
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const [users, setUsers] = useState([]);

  const getUsers = useCallback(() => {
    setLoading(true);
    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
          query($type: UserTypeEnum, $page: Float, $limit: Float, $name: String) {
            adminListUsers(input: { type: $type, page: $page, limit: $limit, name: $name }) {
              count
              rows {
                id
                name
                email
                cpf
                phone
                status
                type
                active
                createdAt
              }
            }  
          }
        `,
        variables: {
          type: 'PATIENT',
          page: page + 1,
          limit,
          name
        }
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.errors) {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "error",
            message:
              "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
          }));
          return
        }
        setCount(res.data.adminListUsers.count)
        setUsers(res.data.adminListUsers.rows)
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
        }));
      });
  }, [limit, page, sessionToken, name]);

  const openEdit = (user) => {
    setUser(user)
    setOpenEditModal(true)
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div className={classes.root}>
      <div className="header">
        <h1>Pacientes</h1>
      {/* </div>
      <div className="filter"> */}
        <Input
          type="text"
          label="Filtrar Nome"
          width="265px"
          value={name}
          onChange={(e) => {setName(e.target.value)}}
        />
      </div>
      <div className="list-admin">
        {loading && <Loading size={80} />}
        {!loading && (
          <>
            {users.length === 0 && (
              <Alert severity="info">
                Não foi encontrado nenhum usuário cadastrado.
              </Alert>
            )}
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Cadastrado em</TableCell>
                    <TableCell>Ativo</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow key={index} >
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{typeMask(user.type)}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{telephoneMask(user.phone)}</TableCell>
                      <TableCell>{format(new Date(user.createdAt), 'dd/MM/yyyy - HH:mm')}</TableCell>
                      <TableCell>{maskActive(user.active)}</TableCell>
                      <TableCell>
                      <Tooltip title="Editar cadastro" arrow>
                          <img
                            src={pencil}
                            alt="editar"
                            style={{ marginRight: "19px" }}
                            onClick={() => {
                              openEdit(user)
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      count={count}
                      rowsPerPage={limit}
                      page={page}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${count !== -1 ? count : `maior que ${to}`
                        }`
                      }
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: false,
                      }}
                      labelRowsPerPage="Linhas por página"
                      onChangePage={(e, newPage) => {
                        setPage(newPage);
                      }}
                      onChangeRowsPerPage={(event) => {
                        setLimit(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      { openEditModal && 
        <ModalEdit
          user={user}
          onSuccess={getUsers}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          sessionToken={sessionToken}
          setStateSnackBar={setStateSnackBar}
        />
      }
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
}

export default () => {
  return <ResponsiveDrawer component={Users} />;
}
