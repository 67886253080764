import { combineReducers } from "redux";

// import RegisterConsultations from "./registerConsultations";
// import Exams from "./exams";
// import Proceedings from "./proceeding";
// import OtherServices from "./otherServices";
// import Address from "./address";
// import PersonalDatas from "./personalDatas";

import User from './user'

export default combineReducers({
  User
});
