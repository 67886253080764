const INITIAL_STATE = {
  user: undefined,
  sessionToken: undefined,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        sessionToken: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        user: action.payload,
      };
    case "CLEAR_USER_DATA":
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
}
