import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { DownloadButton } from "./downloadButton";
const useStyles = makeStyles((theme) => ({
   
    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    },
    modalContentText: {
      ...theme.font.title,
      fontWeight: "normal",
      fontSize: 14,
    },
    
  }));


export function Attachments(props){

    const downloadButton = (link) => {
        return  <DownloadButton link={link} />
    }

    const classes = useStyles();
    const array = props.attachments
    const callback = ({ title, link, conditional }, index) => {
        if (conditional === undefined) {
          return (
            <span key={link + index}>
              <Typography
                style={{ marginTop: index > 0 && 21 }}
                className={classes.modalContentText}
              >
                {title}: {link && downloadButton(link) }
              </Typography>
            </span>
          );
        }
        if (conditional) {
          return (
            <span key={link + index}>
              <Typography
                style={{ marginTop: index > 0 && 21 }}
                className={classes.modalContentText}
              >
                {title}: {link && downloadButton(link)}
              </Typography>
            </span>
          );
        }
      };
      return (
        <Grid item xs={12} className={classes.modalContentItem}>
          <Typography className={classes.modalContentTitle}>Anexos</Typography>
          {array.map(callback)}
        </Grid>
      );
}