import empty from "./empty";

export default (email) => {
  if (empty(email).error) {
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  } else if (email.indexOf("@") === -1) {
    return { error: true, typeError: "O campo email precisa conter o '@'" };
  } else if (!email.split("@")[0]) {
    return { error: true, typeError: "Email inválido" };
  } else if (email.split("@")[1].indexOf(".") === -1) {
    return { error: true, typeError: "Email inválido" };
  } else if (!email.split("@")[1].split(".")[0]) {
    return { error: true, typeError: "Email inválido" };
  } else if (!email.split("@")[1].split(".")[1]) {
    return { error: true, typeError: "Email inválido" };
  } else {
    return { error: false, typeError: "" };
  }
};
