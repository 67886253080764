import React, { useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import Loading from "../../common/circular-progress";
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableCell: {
    backgroundColor: theme.palette.backgroundMain,
    color: "#6B6C7E",
    fontSize: 14,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
      fontSize: 14,
    },
  },
  badgeOk: {
    backgroundColor: "#EDF9F0",
    textTransform: "uppercase",
    color: "#287D3C",
  },
  badgeDenied: {
    backgroundColor: "#FEEFEF",
    textTransform: "uppercase",
    color: "#DA1414",
  },
  badgePendingApproval: {
    backgroundColor: "#FFF4EC",
    textTransform: "uppercase",
    color: "#B95000",
  },
}));

export default function DataTable(props) {
  const classes = useStyles();
  const { onClickRow } = props;
  const [rows] = useState(props.rows || []);
  const [header] = useState(props.header);
  const [count] = useState(props.count || rows.length);
  const [limit, setLimit] = useState(props.limit || 10);
  const [page, setPage] = useState(props.page || 0);
  const [loading, setLoading] = useState(false)

  const { onChangeLimit, onChangePage } = props;

  useEffect(() => onChangeLimit(limit), [limit, onChangeLimit]);
  useEffect(() => onChangePage(page), [page, onChangePage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handlePageCheckPageAndSetPaginationLabel({from, count, to}){
    setLoading(true)
    if(from > count)
     setPage(0)

    setLoading(false)
     return `${from}-${to} de ${count !== -1 ? count : `maior que ${to}`}`
  }

  const status = props.status;
  const types = props.types;

  return (
    <Fragment>
       {loading ? (
              <Loading alignItems="flex-start" position="inherit" />
            ):
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.head}>
            {rows.length > 0 && (
              <TableRow>
                {header.map((item, index) => (
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                    key={index}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          {rows.length === 0 ? (
            <Grid>
              <Alert severity="info">
                Não foi encontrado nenhum dado com esse filtro de pesquisa
              </Alert>
            </Grid>
          ) : (
            <TableBody>
              {rows.map((row, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell className={classes.tableRow} align="center">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableRow} align="center">
                    {types[row.type]}
                  </TableCell>
                  <TableCell className={classes.tableRow} align="center">
                    {moment(row.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell className={classes.tableRow} align="center">
                    <Badge
                      badgeContent={status[row.status].name}
                      classes={{ badge: classes[status[row.status].class] }}
                    ></Badge>
                  </TableCell>
                  <TableCell className={classes.tableRow} align="center">
                    <Tooltip title="Visualizar cadastro" arrow>
                      <AssignmentIcon
                        onClick={() => onClickRow(row)}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
          <TableFooter className={classes.tableCell}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[1, 10, 20, 30]}
                count={count}
                rowsPerPage={limit}
                page={page}
                labelDisplayedRows={({ from, to, count }) => (
                handlePageCheckPageAndSetPaginationLabel({from, to, count})
                )}
                labelRowsPerPage="Linhas por página"
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: false,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      }
    </Fragment>
  );
}
