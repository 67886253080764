import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    }
  }));
  

export function ApprovalRadio(props) {
    const classes = useStyles();
    const {row, setToApprove} = props
return (
    <>
      {row.status === "PENDING_APPROVAL" && (
          <Grid
            item
            xs={12}
            style={{
              borderTop: "1px solid black",
              marginBottom: 28,
              marginTop: 44,
            }}
          ></Grid>
        )}

      {row.status === "PENDING_APPROVAL" && (
          <Grid item xs={12} className={classes.modalContentItem}>
            <Typography className={classes.modalContentTitle}>
              Aprovação
            </Typography>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="approved"
                    control={
                      <Radio
                        onChange={(event) =>
                          setToApprove(event.target.value)
                        }
                      />
                    }
                    label="Aprovado"
                  />
                  <FormControlLabel
                    value="disapproved"
                    control={
                      <Radio
                        onChange={(event) =>
                          setToApprove(event.target.value)
                        }
                      />
                    }
                    label="Reprovado"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        )}
    </>
)
}