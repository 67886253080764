import React, { useState } from "react";
import Api from "../../../../api";

import Modal from "../../../../components/Modal";

import { makeStyles } from "@material-ui/core/styles";
import SnackBar from "../../../../common/snackbar";
import Loading from "../../../../common/circular-progress";

import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";
import emailValidator from "../../../../common/validators/email";

const useStyles = makeStyles({
  root: {
    width: "499px",
  },
});

export default (props) => {
  const classes = useStyles();

  const { openInviteModal, setOpenInviteModal, sessionToken, showAlert } = props;

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState({ error: false, typeError: "" });
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({ error: false, typeError: "" });
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const handleAlert = (show, time = 5000) =>{
    showAlert(show)
    setTimeout(() => showAlert(false), time);
  }

  const sendInvite = () => {
    setLoading(true);
    if (empty(name).error || emailValidator(email).error) {
      setNameError(empty(name));
      setEmailError(emailValidator(email));
      setLoading(false);
    } else {
      const input = { name, email };
      Api.graphql({
        headers: { authorization: `Bearer ${sessionToken}` },
        body: {
          query: `
            mutation createAdministrator($input:CreateAdministratorInput!){
              createAdministrator(input:$input)          
            }
          `,
          variables: {
            input,
          },
        },
      })
        .then((res) => {
          setLoading(false);
          if (res.errors) {
            setStateSnackBar({
              ...stateSnackBar,
              open: true,
              color: "error",
              message: "O email informado já foi enviado anteriormente!",
            });
            handleAlert(false);
          } else {
            setStateSnackBar({
              ...stateSnackBar,
              open: true,
              color: "success",
              message: "Convite enviado com sucesso!",
            });
            handleAlert(true);
            props.getListAdmin();
            setOpenInviteModal(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setStateSnackBar({
            ...stateSnackBar,
            open: true,
            color: "error",
            message:
              "Houve um erro ao tentar enviar o convite, por favor, tente novamente mais tarde ",
          });
          console.log(err);
          handleAlert(false);
        });
    }
  };
  const body = () => (
    <div className={classes.root}>
      {loading && (
        <Loading size={80} position="absolute" height="30vh" width="30vw" />
      )}
      <Input
        disabled={loading}
        name="input-name-invite-admin"
        datatest="input-name-invite-admin"
        type="text"
        label="Nome"
        width="100%"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => setNameError(empty(name))}
        error={nameError.typeError}
      />
      <Input
        disabled={loading}
        name="input-email-invite-admin"
        datatest="input-email-invite-admin"
        type="email"
        label="Email"
        width="100%"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={() => setEmailError(emailValidator(email))}
        error={emailError.typeError}
      />
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );

  return (
    <Modal
      open={openInviteModal}
      title="Usuário"
      body={body()}
      onClose={() => setOpenInviteModal(false)}
      onSubmit={() => sendInvite()}
      loading={loading}
    />
  );
};
