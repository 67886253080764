import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Logo from './../../assets/images/header/logo-vc-horizontal.svg'
import { useLocation } from "react-router-dom";
import Profile from '../Profile'
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    marginRight: theme.spacing(10),
    left: '30px',
    top: '15px',
    '& img': {
      width: 100,
      height: 70
    }
  },
  menu: {
    paddingRight: 40
  },
  profile: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    justifyItems: 'flex-end'
  },
  username: {
    fontWeight: 600,
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: '150%'
  },
  role: {
    fontWeight: 'normal',
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: '150%'
  },
  profileButton: {
    display: 'flex',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    justifyItems: 'flex-end'
  }
}));

export default function Header() {
  const classes = useStyles();
  const theme = useTheme()

  const [user] = useState(useSelector(state => state.User.user))

  const { pathname } = useLocation()

  const menuActive = {
    ...theme.typography,
    color: theme.palette.primary.main,
    '&:focus, &:hover': {
      textDecoration: 'none'
    },
    cursor: 'pointer',
    fontSize: 16
  }

  const menuItem = {
    ...theme.typography,
    textDecoration: 'none',
    '&:focus, &:hover': {
      textDecoration: 'none'
    },
    cursor: 'pointer',
    fontSize: 16
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" elevation={1}>
        <Toolbar>
          <div edge="start" className={classes.logo} color="inherit" aria-label="menu">
            <img src={Logo} alt="logo-vida-connect" />
          </div>

          <Typography component={'span'} variant="h6" className={classes.menu}>
            <NavLink exact to="/" style={
              pathname === '/' ?
                { ...menuActive } :
                { ...menuItem }
            }>
              Dashboard
            </NavLink>
          </Typography>
          <Typography component={'span'} variant="h6" className={classes.menu}>
            <NavLink to="/approvals" style={
              pathname === '/approvals' ?
                { ...menuActive } :
                { ...menuItem }
            }>
              Aprovações
            </NavLink>
          </Typography>
          <Typography component={'span'} variant="h6" className={classes.menu}>
            <NavLink to="/platform/administrators" style={
              pathname.includes('/platform/') ?
                { ...menuActive } :
                { ...menuItem }
            }>
              Plataforma
            </NavLink>
          </Typography>
          <Profile user={user} />
        </Toolbar>
      </AppBar>
    </div>
  );
}
