import React, {useState} from "react"
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useSelector } from "react-redux";
import Api from "../../../api";
import Modal from "./../../../components/Modal";

export function DisapprovalDescription(props){
    const {openDescriptionModal, setOpenDescriptionModal} = props

    const {
        handleCloseModal,
        stateSnackBar,
        setStateSnackBar,
        rowSelected,
        setOpenModal,
        setFetchingData,
        rows,
        setRows,
        setLoadingConfirm } = props

    
    const sessionToken = useSelector((state) => state.User.sessionToken);

    const [disapprovedDescription, setDisapprovedDescription] = useState(
        undefined
      );

     const confirmDescriptionModal = () => {

        if(!descriptionIsValid()){
            setSnackBarOpen("Você precisa descrever o motivo da reprovação");
            return
        }   
        const input = createInput() 
        sendDisapprovalToApi(input)

      };

      const descriptionIsValid =() => {
        if (!disapprovedDescription) {
            return false
          }
        return true
      }

      const createInput = () => {
        return {
            userId: rowSelected.id,
            approved: false,
            disapprovalReason: disapprovedDescription,
          };
      }

      const sendDisapprovalToApi =async (input) => {
        
        return handleAdminApprovalsRequest(input)
               .then((res) => {
                    handleApprovalResponse(res)
                })
               .catch(() => {
                     setSnackBarOpen("Houve um erro ao tentar reprovar este usuário. Por favor, tente novamente mais tarde")
                     setCloseModals()
                });
      }

      const handleAdminApprovalsRequest = async(input) =>{
        setLoadingConfirm(true)
        return Api.graphql({
            headers: { authorization: `Bearer ${sessionToken}` },
            body: {
              query: `
                mutation($input: ApprovalsInput!) {
                  adminApprovals(input: $input)
                }
              `,
              variables: { input },
            },
          })
      }

      const handleApprovalResponse = async(res) => {
        const { errors } = res;
        if (errors) {
            setSnackBarOpen("Houve um erro ao tentar reprovar este usuário. Por favor, tente novamente mais tarde")
            setCloseModals()
            return;
          }

          setSnackBarOpen("Usuário foi reprovado com sucesso!", "success")
          setCloseModals()
          updateRows()
      }

      const setSnackBarOpen = (message, color = "error") => {
        setStateSnackBar({
            ...stateSnackBar,
            open: true,
            color: color,
            message: message
          });
       }

      const setCloseModals = () => {
        setOpenDescriptionModal(false);
        setOpenModal(false);
        setLoadingConfirm(false);
      }

      const updateRows = () => {
          setFetchingData(true);
          setRows(rows.filter((row) => row.id !== rowSelected.id));
          setFetchingData(false);
       }

    return (
        <Modal
        title="Descreva o motivo da reprovação"
        open={openDescriptionModal}
        body={
          <TextareaAutosize
            onChange={(event) =>
              setDisapprovedDescription(event.target.value)
            }
            aria-label="minimum height"
            rowsMin={3}
            placeholder="Descreva o motivo da reprovação"
          />
        }
        onClose={() => handleCloseModal(setOpenDescriptionModal)}
        onSubmit={confirmDescriptionModal}
        labelCancel={
          rowSelected.status === "PENDING_APPROVAL" ? "Cancelar" : "Fechar"
        }
      />
    )
}