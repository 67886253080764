import React from 'react'
import ResponsiveDrawer from '../../../components/ResponsiveDrawer'

function Campaigns() {
  return (
    <h1>Campanhas</h1>
  )
}

export default () => {
  return (
    <ResponsiveDrawer component={Campaigns} />
  )
}
