import React from 'react'
import Button from '@material-ui/core/Button'

export default function ButtonStyled(props) {
  return (
    <Button
      disableElevation
      disableRipple
      disabled={props.disabled}
      size={props.size || "medium"}
      variant={props.variant || "outlined"}
      color={props.color || 'primary'}
      elevation={props.elevation || 0}
      endIcon={props.icon}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  )
}
