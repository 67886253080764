import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const snackBarStyle = makeStyles({
  error: {
    "& div.MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6": {
      backgroundColor: "#ff6347",
      color: "#F3F3F3",
      minHeight: "70px",
    },
  },

  success: {
    "& div.MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6": {
      backgroundColor: "green",
      color: "#fff",
      minHeight: "70px",
    },
  },
});

export default (props) => {
  const classesSnackBar = snackBarStyle();

  const { stateSnackBar, setStateSnackBar } = props;

  const { vertical, horizontal, open, message, color } = stateSnackBar;

  return (
    <Snackbar
      className={classesSnackBar[color || 'error']}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={() => setStateSnackBar({ ...stateSnackBar, open: false })}
      message={message}
      autoHideDuration={5000}
      key={vertical + horizontal}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setStateSnackBar({ ...stateSnackBar, open: false })}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};
