import React, { useState } from "react";

import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Api from "../../../../api";

import Modal from "../../../../components/Modal";


import Loading from "../../../../common/circular-progress";

import Input from "../../../../common/input";

import isEmail from "../../../../common/validators/email";
import telephoneMask from './../../../../common/mask/telephone'

const useStyles = makeStyles({
  root: {
    width: "499px",

    "& .MuiOutlinedInput-input": {
      padding: "10px 14px;",
      paddingRight: "32px"
    }
  },
  select: {
    marginLeft: 20,
    marginTop: 15,
  }
});

export default (props) => {
  const classes = useStyles();

  const {
    user,
    onSuccess,
    openEditModal,
    setOpenEditModal,
    sessionToken,
    setStateSnackBar,
  } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(user)
  
  const saveUser = () => {
    setLoading(true)
    if(isEmail(data.email).error) {
      setStateSnackBar((state) => ({
        ...state,
        open: true,
        color: "error",
        message: isEmail(data.email).typeError
      }));
      setLoading(false)
    } else {
      Api.graphql({
        headers: { authorization: `Bearer ${sessionToken}` },
        body: {
          query: `
            mutation{
              updateUser(
                user: {
                  id: "${data.id}",
                  email: "${data.email}",
                  active: ${data.active}
                }
              )          
            }
          `,
        },
      }).then((res) => {
        const status = res?.data?.updateUser || false
        setLoading(false)
        if(status) {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "success",
            message: "Usuário atualizado com sucesso"
          }));
          setOpenEditModal(false)
          onSuccess()
        }  else if(res.errors) {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "error",
            message: res.errors[0].message
          }));
        } else {
          setStateSnackBar((state) => ({
            ...state,
            open: true,
            color: "error",
            message: "Ocorreu um erro ao tentar atualizar o usuário"
          }));
        }
      }).catch((error) => {
        setLoading(false)
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message: "Ocorreu um erro ao tentar atualizar o usuário"
        }));
      })
    }
  }

  const handleChange = (evt) => {
    setData({
      ...data,
      [evt.target.name]: evt.target.value
    })
  } 
  
  const body = () => (
    <div className={classes.root}>
      {loading && (
        <Loading size={80} position="absolute" height="30vh" width="30vw" />
      )}
      <Input
        disabled
        name="name"
        datatest="input-name-invite-admin"
        type="text"
        label="Nome"
        width="100%"
        value={data?.name}
      />
      <Input
        disabled={loading}
        name="email"
        datatest="input-email-invite-admin"
        type="email"
        label="Email"
        width="100%"
        value={data?.email}
        onChange={handleChange}
      />
      
      <Grid container>
        <Grid item xl={6}>
          <Input
            disabled
            name="phone"
            datatest="input-email-invite-admin"
            type="email"
            label="Telefone"
            width="100%"
            value={telephoneMask(data?.phone) || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xl={6} className={classes.select}>
        <div>
          <strong className="label-input">Status</strong>
        </div>
          <Select
            disabled={loading}
            name="active"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            size="small"
            value={data.active}
            onChange={handleChange}
          >
            <MenuItem value={false}>Inativo(a)</MenuItem>
            <MenuItem value={true}>Ativo(a)</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal
      open={openEditModal}
      title="Editar Usuário"
      body={body()}
      labelConfirm="Confirmar"
      onClose={() => setOpenEditModal(false)}
      onSubmit={() => saveUser()}
      loading={loading}
    />
  );
};
