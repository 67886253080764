import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import Api from "../../../api";
import ResponsiveDrawer from "../../../components/ResponsiveDrawer";

import { makeStyles } from "@material-ui/core/styles";

import pencil from "../../../assets/icons/pencil.svg";
import trash from "../../../assets/icons/trash.svg";

import Loading from "../../../common/circular-progress";
import SnackBar from "../../../common/snackbar";
import { Alert } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import ButtonStyled from "../../../components/ButtonStyled";

import ModalInvite from "./modal/invite";
import ModalInactivate from "./modal/inactivate";
import ModalEdit from "./modal/edit";

const useStyles = makeStyles({
  root: {
    "& div.header": {
      margin: "15px 0px 32px 0px",
      display: "flex",
      justifyContent: "space-between",
      "& h1": {
        margin: 0,
      },
      "& .MuiButton-outlinedSizeSmall": {
        height: "32px",
        minWidth: "195px",
      },
    },
    "& div.list-admin": {
      "& img": {
        cursor: "pointer",
      },
      "& thead": {
        backgroundColor: "#F7F8F9",
        "& th": {
          color: "#6B6C7E",
          fontSize: "14px",
        },
      },
      "& tr": {
        height: "64px",
      },
      "& .MuiTableCell-footer": {
        backgroundColor: "#F7F8F9",
      },
    },
  },
});

function Administrators() {
  const classes = useStyles();

  const json = JSON.parse(localStorage.getItem("persist:root")).User;
  let id = "";
  if (JSON.parse(json).user) id = JSON.parse(json).user.id;

  const sessionToken = useSelector((state) => state.User.sessionToken);
  const [loading, setLoading] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const [openInactivateModal, setOpenInactivateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [infoUserAdmin, setInfoUserAdmin] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [stateSnackBar, setStateSnackBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
  });

  const [listAdmin, setListAdmin] = useState([]);

  const getListAdmin = useCallback(() => {
    setLoading(true);
    Api.graphql({
      headers: { authorization: `Bearer ${sessionToken}` },
      body: {
        query: `
            query{
              listUsersAdmin(limit:${limit}, page:${page + 1}){
                count
                rows{
                  id
                  name
                  email
                }
              }
            }
          `,
      },
    })
      .then((res) => {
        setLoading(false);
        setListAdmin(res.data.listUsersAdmin.rows);
        setCount(res.data.listUsersAdmin.count);
      })
      .catch((err) => {
        setLoading(false);
        setStateSnackBar((state) => ({
          ...state,
          open: true,
          color: "error",
          message:
            "Houve um erro ao buscar os dados, por favor, tente novamente mais tarde ",
        }));
        console.log(err);
      });
  }, [limit, page, sessionToken]);

  useEffect(() => {
    getListAdmin();
  }, [getListAdmin]);

  return (
    <div className={classes.root}>
      <div className="header">
        <h1>Administradores</h1>
        <ButtonStyled
          size="small"
          label="Novo Usuário"
          color="secondary"
          onClick={() => setOpenInviteModal(true)}
        />
      </div>
      <div className="list-admin">
        {loading && <Loading size={80} />}
        {!loading && (
          <>
          {alertState && (<Alert severity="success">Convite enviado com sucesso!</Alert>)}
            {listAdmin.length === 0 && (
              <Alert severity="info">
                Não foi encontrado nenhum administrador cadastrado.
              </Alert>
            )}
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listAdmin.map((admin, index) => (
                    <TableRow key={index}>
                      <TableCell>{admin.name}</TableCell>
                      <TableCell align="right">
                        {admin.id !== id && (
                          <Tooltip title="Excluir cadastro" arrow>
                            <img
                              src={trash}
                              alt="excluir"
                              style={{ marginRight: "34px" }}
                              onClick={() => {
                                setInfoUserAdmin(admin);
                                setOpenInactivateModal(true);
                              }}
                            />
                          </Tooltip>
                        )}

                        <Tooltip title="Editar cadastro" arrow>
                          <img
                            src={pencil}
                            alt="editar"
                            style={{ marginRight: "19px" }}
                            onClick={() => {
                              setInfoUserAdmin(admin);
                              setOpenEditModal(true);
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[1, 10, 20, 30]}
                      count={count}
                      rowsPerPage={limit}
                      page={page}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} de ${
                          count !== -1 ? count : `maior que ${to}`
                        }`
                      }
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: false,
                      }}
                      labelRowsPerPage="Linhas por página"
                      onChangePage={(e, newPage) => {
                        setPage(newPage);
                      }}
                      onChangeRowsPerPage={(event) => {
                        setLimit(parseInt(event.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      {openInviteModal && (
        <ModalInvite
          openInviteModal={openInviteModal}
          setOpenInviteModal={setOpenInviteModal}
          sessionToken={sessionToken}
          getListAdmin={getListAdmin}
          showAlert={setAlertState}
        />
      )}
      {openInactivateModal && (
        <ModalInactivate
          infoUserAdmin={infoUserAdmin}
          openInactivateModal={openInactivateModal}
          setOpenInactivateModal={setOpenInactivateModal}
          sessionToken={sessionToken}
          getListAdmin={getListAdmin}
          setStateSnackBar={setStateSnackBar}
        />
      )}
      {openEditModal && (
        <ModalEdit
          infoUserAdmin={infoUserAdmin}
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          sessionToken={sessionToken}
          getListAdmin={getListAdmin}
          setStateSnackBar={setStateSnackBar}
        />
      )}
      <SnackBar
        stateSnackBar={stateSnackBar}
        setStateSnackBar={setStateSnackBar}
      />
    </div>
  );
}

export default () => {
  return <ResponsiveDrawer component={Administrators} />;
};
