import React from "react"
import Modal from "./../../../components/Modal";
export function Details(props){
    const {openModal, setOpenModal, modalContent, rowSelected,
          handleCloseModal, toApprove, setStateSnackBar, stateSnackBar,
          setOpenDescriptionModal, setOpenConfirmModal} = props


    const confirmModal = () => {
        if (!toApprove) {
          return setStateSnackBar({
            ...stateSnackBar,
            open: true,
            message:
              'Para salvar você precisa selecionar "Aprovado" ou "Reprovado"',
          });
        }
    
        if (toApprove === "disapproved") {
          setOpenDescriptionModal(true);
          return;
        }
    
        setOpenConfirmModal(true);
      };

    return (
        <Modal
        open={openModal}
        title="Detalhe do prestador"
        body={modalContent}
        onClose={() => handleCloseModal(setOpenModal)}
        onSubmit={confirmModal}
        showConfirmButton={rowSelected.status === "PENDING_APPROVAL"}
        labelCancel={
          rowSelected.status === "PENDING_APPROVAL" ? "Cancelar" : "Fechar"
        }
      />
    )
}