import React, { useEffect, useState, Fragment } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import Header from './../Header';
import API from './../../api'
import * as UserActions from './../../common/store/actions/user'
import Loading from './../../common/circular-progress'

export default ({ component, ...rest }) => {
  const history = useHistory();
  const sessionToken = useSelector(state => state.User.sessionToken)
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  useEffect(() => {
    const clearSession = () => {
      dispatch(UserActions.clearUserData())
    }

    const redirectToLogin = () => history.push("/login")

    API.graphql({
      body: {
        query: `
        query {
          checkSessionTokenAdmin
        }
      `
      },
      headers: { authorization: `Bearer ${sessionToken}` }
    })
      .then(({ data, errors }) => {
        if (errors || !data) {
          clearSession()
          redirectToLogin()
          setLoading(false)
        }
        setLoading(false)
      })
      .catch(() => {
        clearSession()
        redirectToLogin()
        setLoading(false)
      })
  }, [history, sessionToken, dispatch]);
  return (
    <Fragment>
      <Header />
      {loading ? <Loading size={80} /> : <Route {...rest} render={() => React.createElement(component, { ...rest })} />}
    </Fragment>
  );
};
