import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    
    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    },
    modalContentText: {
      ...theme.font.title,
      fontWeight: "normal",
      fontSize: 14,
    },
   
  }));

export function ContentItem({ title, text, styles, xs, conditional, id }){
    const classes = useStyles();
    
    if (conditional === undefined) {
        return (
          <Grid
            key={id}
            item
            xs={xs || 12}
            className={classes.modalContentItem}
          >
            <Typography
              style={{ ...styles }}
              className={classes.modalContentTitle}
            >
              {title}
            </Typography>
            <Typography className={classes.modalContentText}>{text}</Typography>
          </Grid>
        );
      }
  
      if (conditional) {
        return (
          <Grid
            key={id}
            item
            xs={xs || 12}
            className={classes.modalContentItem}
          >
            <Typography
              style={{ ...styles }}
              className={classes.modalContentTitle}
            >
              {title}
            </Typography>
            <Typography className={classes.modalContentText}>{text}</Typography>
          </Grid>
        );
      }
}