import React from "react"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
 
    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    },
    modalContentText: {
      ...theme.font.title,
      fontWeight: "normal",
      fontSize: 14,
    },
  }));

export function BankData({ bank, agency, account }){
    const classes = useStyles();
    
    return (
        <Grid item xs={12} className={classes.modalContentItem}>
        <Typography className={classes.modalContentTitle}>
          Dados Bancários
        </Typography>
        <Typography className={classes.modalContentText}>
          Banco: {bank}
        </Typography>
        <Typography className={classes.modalContentText}>
          Agência: {agency}
        </Typography>
        <Typography className={classes.modalContentText}>
          Conta: {account}
        </Typography>
      </Grid>
    )
}