import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import cepMask from "../../../common/mask/cep";
import telephoneMask from "../../../common/mask/telephone";
import { ContentItem } from "./dialogContentItem";

const useStyles = makeStyles((theme) => ({

    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    },
  }));

export function Addresses({addresses}){
    const classes = useStyles();

    return (
        addresses.map((address, index) => {
            const addressConfig = [
              { title: "CNES", key: "cnes", xs: 12 },
              { title: "Pais", key: "country", xs: 4 },
              { title: "CEP", key: "zipCode", format: cepMask, xs: 8 },
              { title: "Endereço", key: "address", xs: 8 },
              { title: "Número", key: "number", xs: 4 },
              { title: "Complemento", key: "complement", xs: 12 },
              { title: "Bairro", key: "neighborhood", xs: 5 },
              { title: "Cidade", key: "city", xs: 5 },
              { title: "Estado", key: "district", xs: 2 },
              { title: "Telefone", key: "phone", format: telephoneMask, xs: 6 },
              { title: "Whatsapp", key: "whatsapp", format: telephoneMask, xs: 6 },
            ];
            const addressArray = addressConfig
              .map(
                (i) =>
                  address[i.key] && {
                    ...i,
                    text: i.format ? i.format(address[i.key]) : address[i.key],
                    xs: address.xs || i.xs,
                  }
              )
              .filter((i) => i);
            return (
              <Fragment key={address.id || `root-address-${index}`}>
                <Grid item xs={12} className={classes.modalContentItem}>
                  <Typography
                    className={classes.modalContentTitle}
                    style={{ fontSize: 24 }}
                  >
                    Endereço {++index}
                  </Typography>
                </Grid>
                {addressArray.map((i, key) =>
                 
                 ( <ContentItem 
                  title={i.title}
                  text={i.text}
                  styles={i.styles}
                  xs={i.xs}
                  conditional={i.conditional}
                  key={`${index}-${i.key}-${key}`}
                  id={`${index}-${i.key}-${key}`}
                  />)

                )}
              </Fragment>
            );
          })
    )
}