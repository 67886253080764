export default function percentageMask(value) {
  value = value.toString().replace(/\D/g, "");
  if (value === "") {
    value = 0;
  } else if (value.split("")[0] === "0" && value.length > 1) {
    value = value.slice(1);
  }

  value = value.toString().replace(/(\d)$/, "$1%");

  return value;
}
