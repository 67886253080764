import React from "react";
import Grid from "@material-ui/core/Grid";
import { dialogContentAddresses, dialogContentItem, dialogContentHealthOperators,
         dialogContentBankData, dialogContentAttachments, types, createInput } from "../utils";
import { ApprovalRadio } from "../../components/ApprovalRadio";
import cnpjMask from "../../../../common/mask/cnpj";
import { setSnackBarOpen } from "../utils";
import { listUsers } from "../utils";


export const dialogClinic = ({row, setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => {
    const rows = establishmentsQuery("clinic")
    const input = createInput(1, 1, row)

    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde", stateSnackBar, setStateSnackBar)
          return;
        }
   
        const row = data.listUsersPendingApproval.rows[0];
        return getModalInfo("clinic" ,row, setToApprove)   
      })
      .catch((err) => {
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
      });
  };

export const dialogLaboratory = ({row, setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => {
    
    const rows = establishmentsQuery("laboratory")
    const input = createInput(1, 1, row)

    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
          return;
        }
        
        const row = data.listUsersPendingApproval.rows[0];
        return getModalInfo("laboratory", row, setToApprove)       
      })
      .catch((err) => {
        console.log('ERROR', err)
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
      });
  };

export const dialogHospital = ({row, setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => {
    const rows = establishmentsQuery("hospital")
    const input = createInput(1, 1, row)
    
    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
          return;
        }
        const row = data.listUsersPendingApproval.rows[0];
        return getModalInfo("hospital" ,row, setToApprove)   
      })
      .catch((err) => {
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde", stateSnackBar, setStateSnackBar)
      });
  };

  const establishmentsQuery = (establishmentType) => {
    return `
    id
    name
    email
    cpf
    phone
    status
    type
    establishment {
      id
      establishmentHasPlans{
        plans{
          healthOperator{
            fantasyName
            companyName
          }
        }
      }
      ${establishmentType} {
        id
        tradingName
        companyName
        cnpj
        companyEmail
        technicianResponsibleName
        technicianResponsibleDocument
        legalResponsibleName
        legalResponsibleDocument
        companyLogo
        bank
        agency
        account
        numberOfInstallments
        minimumInstallmentAmount
        socialContract
        cnpjAttachment
        companyResponsibleAttachment
        technicianResponsibleDocumentAttachment
        proofResidence
        form
        careModality
      }
      unit {
        id
        cnes
        country
        zipCode
        address
        number
        complement
        neighborhood
        district
        city
        phone
        whatsapp
      }
    }
    createdAt
  `;
  }

  const getModalInfo = (establishmentType, row, setToApprove) => {
      
      const { establishment } = row;
      const { unit, establishmentHasPlans } = establishment;
      establishmentType =  establishment[String(establishmentType)]
      const { bank, agency, account } = establishmentType;
  
      const contentItens = contentItems(establishmentType, row) 
      const attachments = getAttachments(establishmentType)
      const healthOperators = mapHealthOperators(establishmentHasPlans)
  
      return (
        <Grid container>
          {contentItens.map((i, index) =>
            dialogContentItem(i, `${row.id}-${index}`)
          )}
          {healthOperators.length > 0 &&
            dialogContentHealthOperators(healthOperators)}
          {dialogContentBankData({ bank, agency, account })}
          {dialogContentAttachments(attachments)}
          {dialogContentAddresses(unit)}
  
          <ApprovalRadio
          row={row}
          setToApprove={setToApprove}
           /> 
        </Grid>
      );
    }
  
  const contentItems = (establishment, row) => {
      
      return [
        { title: "Tipo do prestador", text: types[row.type] },
        { title: "Nome Fantasia", text: establishment.tradingName },
        { title: "CNPJ", text: cnpjMask(establishment.cnpj) },
        { title: "Email da Empresa", text: establishment.companyEmail },
        { title: "Nome do Responsável Legal", text: establishment.legalResponsibleName },
        { title: "Documento do Responsável Legal", text: establishment.legalResponsibleDocument },
        {
          title: "Nome do Diretor/Responsável Técnico",
          text: establishment.technicianResponsibleName,
        },
        {
          title: "Documento Profissional do Responsável Técnico",
          text: establishment.technicianResponsibleDocument,
        },
      ];
    }
  
  const getAttachments = (establishment) => {
     return [
      {
        title: "1. Contrato social",
        link: `/establishment/download/${establishment.socialContract}`,
      },
      {
        title: "2.	Comprovante de Inscrição e Situação Cadastral - CNPJ",
        link: `/establishment/download/${establishment.cnpjAttachment}`,
      },
      {
        title: "3.	Documento do responsável legal",
        link: `/establishment/download/${establishment.companyResponsibleAttachment}`,
      },
      {
        title: "4.	Documento do responsável técnico",
        link: `/establishment/download/${establishment.technicianResponsibleDocumentAttachment}`,
      },
      {
        title: "5.	Comprovante de endereço",
        link: `/establishment/download/${establishment.proofResidence}`,
      },
      
    ];
    }
    
  const mapHealthOperators = (establishmentHasPlans) => {
        let healthOperators = [];
        if (Array.isArray(establishmentHasPlans)) {
          healthOperators = establishmentHasPlans.map(
            (healthOperator) => healthOperator.plans.healthOperator
          );
        }
      
        return healthOperators
    }