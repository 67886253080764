import React from "react"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    modalContentItem: {
      marginTop: 28,
    },
    modalContentTitle: {
      ...theme.font.title,
      fontWeight: "bold",
      fontSize: 16,
    },
    modalContentText: {
      ...theme.font.title,
      fontWeight: "normal",
      fontSize: 14,
    },
    
  }));

export function HealthOperators({arrayHealthOperators}){
    const classes = useStyles();
    return (
        <Grid item xs={12} className={classes.modalContentItem}>
        <Typography className={classes.modalContentTitle}>Convênios</Typography>
        {arrayHealthOperators.map((healthOperator, index) => (
          <React.Fragment key={index}>
            <Typography className={classes.modalContentText}>
              <strong>Nome Fantasia:</strong> {healthOperator.fantasyName}
            </Typography>
            <Typography
              className={classes.modalContentText}
              style={{ marginBottom: "15px" }}
            >
              <strong>Razão Social:</strong> {healthOperator.companyName}
            </Typography>
          </React.Fragment>
        ))}
      </Grid>
    )
}