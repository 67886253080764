import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: (props) => (props.direction ? props.direction : "column "),
    flexWrap: "wrap",
    border: "none",
    margin: "12px 0 0 0",
    width: (props) => props.width,

    "& .MuiFormHelperText-contained": {
      marginLeft: 0,
      marginTop: 6,
      color: "#000000",
      fontSize: 10,
    },

    "& .MuiFormHelperText-root": {
      color: "#DA1414",
    },

    "& .MuiInput-underline::before": {
      borderBottom: "none !important",
    },

    "& .MuiInput-underline::after": {
      borderBottom: "1px solid #f9b233 !important",
    },
  },
  inputs: {
    height: "40px",
    border: "1px solid #e7e7ed",
    padding: "auto",
    backgroundColor: (props) => (props.disabled ? "#f5f5f5" : "#fff"),
  },
});

export default (props) => {
  const classes = useStyles(props);

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <TextField
          type={props.type}
          fullWidth
          name={props.name}
          data-test={props.datatest}
          placeholder={props.placeholder ? props.placeholder : null}
          helperText={props.error ? props.error : null}
          inputProps={
            props.inputProps
              ? {
                  ...props.inputProps,
                  className: classes.inputs,
                }
              : {
                  className: classes.inputs,
                }
          }
          onChange={props.onChange}
          value={props.value}
          onBlur={props.onBlur}
          InputProps={props.InputProps}
          disabled={props.disabled}
        />
        {props.link ? (
          <span className="forgot-password-text">
            <Link to={props.link}>{props.linkText} </Link>
          </span>
        ) : null}
      </Grid>
    </Grid>
  );
};
