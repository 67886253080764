import React from "react";
import Logon from "./pages/login";
import Home from "./pages/home";
import Approvals from "./pages/approvals";
import { Administrators, Campaigns, Indications, Values, Users } from "./pages/platform";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRoute from './components/AuthRoute'

export default () => (
  <BrowserRouter>
    <Switch>
      <AuthRoute path="/" exact component={Home} />
      <Route path="/login" component={Logon} />
      <AuthRoute path="/approvals" component={Approvals} />
      <AuthRoute path="/platform/administrators" component={Administrators} />
      <AuthRoute path="/platform/campaigns" component={Campaigns} />
      <AuthRoute path="/platform/indications" component={Indications} />
      <AuthRoute path="/platform/values" component={Values} />
      <AuthRoute path="/platform/patients" component={Users} />
    </Switch>
  </BrowserRouter>
);
