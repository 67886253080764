import React from "react";
import Grid from "@material-ui/core/Grid";
import cnpjMask from "../../../../common/mask/cnpj";
import { dialogContentItem,dialogContentBankData, dialogContentAttachments, dialogContentAddresses, 
     listUsers, setSnackBarOpen, types, createInput } from "../utils";
import { ApprovalRadio } from "../../components/ApprovalRadio";


export const dialogHealthOperator = ({row, setToApprove, stateSnackBar, setStateSnackBar, sessionToken}) => {
    const rows = queryHealthOperator()
    const input = createInput(1, 1, row)
    return listUsers(rows, input, sessionToken)
      .then((res) => {
        const { data, errors } = res;
        if (errors) {
          setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
          return;
        }
        const row = data.listUsersPendingApproval.rows[0];
        return getModalInfo(row, setToApprove)
      })
      .catch((err) => {
        console.log(err);
        setSnackBarOpen("Houve um erro ao buscar os dados, por favor, tente novamente mais tarde",stateSnackBar, setStateSnackBar)
      });
  };

  const queryHealthOperator = () => {
    return `
    id
    name
    email
    cpf
    phone
    status
    type
    establishment {
      id
      healthOperator {
        id
        fantasyName
        companyName
        cnpj
        email
        ansRegistry
        technicalManagerName
        technicalManagerDocumentNumber
        legalManagerName
        legalManagerDocumentNumber
        brandLogo
        bank
        agency
        account
        socialContract
        proofCnpj
        legalManagerDocument
        technicalManagerDocument
        proofResidence
        registrationForm
        country
        zipcode
        streetAddress
        streetNumber
        streetAddressComplement
        neighborhood
        state
        city
        telephoneNumber
        whatsappNumber
      }
      unit {
        id
        cnes
        country
        zipCode
        address
        number
        complement
        neighborhood
        district
        city
        phone
        whatsapp
      }
    }
    createdAt
  `;
  }

  const getModalInfo = (row, setToApprove) => {
    
    const { establishment } = row;
    const { healthOperator } = establishment;
    const unit = getUnit(healthOperator)
    const contentItens = getContentItems(row)
    const { bank, agency, account } = healthOperator;
    const attachments = getAttachments(healthOperator)
    
    return (
      <Grid container>
        {contentItens.map((i, index) =>
          dialogContentItem(i, `${row.id}-${index}`)
        )}
        {dialogContentBankData({ bank, agency, account })}
        {dialogContentAttachments(attachments)}
        {dialogContentAddresses(unit)}

        <ApprovalRadio
      row={row}
      setToApprove={setToApprove}
       /> 
      </Grid>
    );
  }

  const getUnit = (healthOperator) => {
    return [
      {
        // cnes: healthOperator.cnes,
        country: healthOperator.country,
        zipCode: healthOperator.zipCode || healthOperator.zipcode,
        healthOperator: healthOperator.healthOperator,
        address: healthOperator.streetAddress,
        number: healthOperator.streetNumber,
        complement: healthOperator.streetAddressComplement,
        neighborhood: healthOperator.neighborhood,
        city: healthOperator.city,
        district: healthOperator.state,
        phone: healthOperator.telephoneNumber,
        whatsapp: healthOperator.whatsappNumber,
      },
    ];
  }

  const getContentItems = (row) => {
    const { healthOperator }  = row.establishment
      return [
        { title: "Tipo do prestador", text: types[row.type] },
        { title: "Nome Fantasia", text: healthOperator.fantasyName },
        { title: "CNPJ", text: cnpjMask(healthOperator.cnpj) },
        { title: "Registro ANS", text: healthOperator.ansRegistry },
        { title: "Email da Empresa", text: healthOperator.email },
        { title: "Nome do Responsável Legal", text: healthOperator.legalManagerName },
        { title: "CPF do Responsável Legal", text:  healthOperator.legalManagerDocumentNumber},
        {
          title: "Nome do Diretor/Responsável Técnico",
          text: healthOperator.technicalManagerName,
        },
        {
          title: "Documento Profissional",
          text: healthOperator.technicalManagerDocumentNumber,
        },
      ];
  }

  const getAttachments = (healthOperator) => {
    return [
      {
        title: "1. Contrato social",
        link: `/establishment/download/${healthOperator.socialContract}`,
      },
      {
        title: "2.	Comprovante de Inscrição e Situação Cadastral - CNPJ",
        link: `/establishment/download/${healthOperator.proofCnpj}`,
      },
      {
        title: "3.	Documento do responsável legal",
        link: `/establishment/download/${healthOperator.legalManagerDocument}`,
      },
      {
        title: "4.	Documento do responsável técnico",
        link: `/establishment/download/${healthOperator.technicalManagerDocument}`,
      },
      {
        title: "5.	Comprovante de endereço",
        link: `/establishment/download/${healthOperator.proofResidence}`,
      },
    ];
  }